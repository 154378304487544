import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'
// eslint-disable-next-line no-unused-vars
import { baseUrl, baseUrlV2, isEmpty, Roles, showMenuBase } from '../plugins/supports'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    params: true,

    meta: {
      auth: true,
      // permissions: [Roles.HOMEMEDIS],
      showInSidebar: true,
      icon: 'mdi-view-dashboard',
      title: 'Dashboard'
    }
  },
  // {
  //   path: '/analisis',
  //   name: 'Analisis',
  //   component: () => import('../views/dashboard/pasien.vue'),
  //   params: true,

  //   meta: {
  //     auth: true,
  //     permissions: [Roles.ROOT],
  //     showInSidebar: true,
  //     icon: 'mdi-clipboard-pulse-outline',
  //     title: 'Analisis'
  //   }
  // },
  {
    path: '/data-patient',
    name: 'DataPasien',
    component: () => import('../views/data-pasien/Index-Pasien.vue'),
    params: true,

    meta: {
      auth: true,
      permissions: [Roles.ROOT, Roles.IGD, Roles.JALAN, Roles.INAP, Roles.Admin],
      showInSidebar: true,
      icon: 'mdi-hospital',
      title: 'Rawat Jalan',
      isRawatJalan: true
    }
  },
  {
    path: '/rawat-inap',
    name: 'DataPasienRawatInap',
    params: true,

    component: () => import('../views/data-pasien/IndexRawatInap.vue'),
    meta: {
      auth: true,
      permissions: [Roles.ROOT, Roles.IGD, Roles.JALAN, Roles.INAP, Roles.Admin],
      showInSidebar: true,
      icon: 'mdi-hospital-box',
      title: 'Rawat Inap',
      isRawatInap: true
    }
  },
  {
    path: '/form-igd',
    name: 'FORMIGD',
    params: true,

    component: () => import('../views/igd/igd.vue'),
    meta: {
      auth: true,
      permissions: [Roles.ROOT, Roles.IGD, Roles.JALAN, Roles.INAP, Roles.Admin],
      showInSidebar: true,
      hasQueryParamPage: true,

      icon: 'mdi-ambulance',
      title: 'IGD',
      isIGD: true
    }
  },

  {
    path: '/modul-igd',
    name: 'ModulIgd',
    params: true,

    component: () => import('../views/igd/ModulIgd.vue'),
    meta: {
      auth: true,
      permissions: [Roles.ROOT, Roles.IGD, Roles.JALAN, Roles.INAP, Roles.Admin],
      showInSidebar: false,
      hasQueryParamPage: true,

      icon: 'mdi-file',
      title: 'Pemeriksaan IGD',
      isIGD: true
    }
  },
  // {
  //   path: '/read-data-dcm',
  //   name: 'READDATA',
  //   params: true,

  //   component: () => import('../views/ReadDCM.vue'),
  //   meta: {
  //     auth: true,
  //     permissions: [Roles.ROOT],
  //     showInSidebar: false,
  //     hasQueryParamPage: true,

  //     icon: 'mdi-file',
  //     title: 'Read data',
  //     isIGD: true
  //   }
  // },
  {
    path: '/file-rekam-medis',
    name: 'RekamMedis',
    component: () => import('../views/file-rekam-medis/FileRekam.vue'),
    params: true,

    meta: {
      auth: true,
      permissions: [Roles.ROOT, Roles.IGD, Roles.JALAN, Roles.INAP, Roles.Admin],
      showInSidebar: true,
      icon: 'mdi-file',
      title: 'Rekam Medis'
    }
  },
  {
    path: '/kata-pasien',
    name: 'KataPasien',
    component: () => import('../views/kata-pasien/KataPasien.vue'),
    params: true,

    meta: {
      auth: true,
      permissions: [Roles.ROOT, Roles.IGD, Roles.JALAN, Roles.INAP, Roles.Admin],
      showInSidebar: true,
      icon: 'mdi-account-voice',
      title: 'Kata Pasien'
    }
  },
  {
    path: '/asesmen-keperawatan',
    name: 'Asesmen',
    params: true,

    component: () => import('../views/rawat-inap/ModulAsesmen.vue'),
    meta: {
      auth: true,
      permissions: [Roles.ROOT, Roles.IGD, Roles.JALAN, Roles.INAP, Roles.Admin],
      showInSidebar: false,
      hasQueryParamPage: true,

      icon: 'mdi-folder',
      title: 'Keperawatan dan Kebidanan',
      isRawatInap: true
    }
  },
  {
    path: '/form-asesmen-awal-medis-pasien-rawat-inap',
    name: 'FormAsesmenAwalMedisPasienRawatInap',
    params: true,

    component: () =>
      import('../views/rawat-inap/FormAsesmenAwalMedisPasienRawatInap.vue'),
    meta: {
      auth: true,
      permissions: [Roles.ROOT, Roles.IGD, Roles.JALAN, Roles.INAP, Roles.Admin],
      showInSidebar: false,
      icon: 'mdi-file',
      hasQueryParamPage: true,
      title: 'Asesmen Medis Pasien Rawat Inap',
      isRawatInap: true
    }
  },
  {
    path: '/form-asesmen-awal-medis-pasien-rawat-inap-anak',
    name: 'FormAsesmenAwalMedisPasienRawatInapAnak',
    params: true,

    component: () =>
      import('../views/rawat-inap/FormAsesmenAwalMedisPasienRawatInapAnak.vue'),
    meta: {
      auth: true,
      permissions: [Roles.ROOT],
      showInSidebar: false,
      icon: 'mdi-file',
      hasQueryParamPage: true,
      title: 'Asesmen Awal Rawat Inap Anak',
      isRawatInap: true
    }
  },
  {
    path: '/cppt-rawat-inap',
    name: 'CPPTRAWATINAP',
    params: true,

    component: () => import('../views/rawat-inap/CpptRawatInap.vue'),
    meta: {
      auth: true,
      permissions: [Roles.ROOT, Roles.IGD, Roles.JALAN, Roles.INAP, Roles.Admin],
      showInSidebar: false,
      icon: 'mdi-file',
      isRawatInap: true,
      hasQueryParamPage: true,
      title: 'CPPT R. Inap'
    }
  },

  // {
  //   path: '/asesmen-persalinan',
  //   name: 'AsesmenPersalinan',
  //   params: true,

  //   component: () =>
  //     import('../views/rawat-inap/AsesmenPersalinan.vue'),
  //   meta: {
  //     auth: true,
  //     permissions: [Roles.ROOT],
  //     showInSidebar: false,
  //     hasQueryParamPage: true,

  //     icon: 'mdi-file',
  //     title: 'Asesmen Persalinan',
  //     isRawatInap: true
  //   }
  // },
  {
    path: '/lembar-konsultasi-inap',
    name: 'lembar-konsultasi-inap',
    component: () => import('../views/igd/LembarKonsultasi.vue'),
    meta: {
      auth: true,
      permissions: [Roles.ROOT, Roles.IGD, Roles.JALAN, Roles.INAP, Roles.Admin],
      showInSidebar: false,
      icon: 'mdi-face-man',
      title: 'Lembar Konsultasi',
      isRawatInap: true
    }
  },
  {
    path: '/form-transfer-rawat-inap',
    name: 'SuratRujukan',
    params: true,

    component: () =>
      import('../views/igd/FormTransferIGD.vue'),
    meta: {
      auth: true,
      permissions: [Roles.ROOT, Roles.IGD, Roles.JALAN, Roles.INAP, Roles.Admin],
      showInSidebar: false,
      hasQueryParamPage: true,

      icon: 'mdi-transfer',
      title: 'Formulir Transfer',
      isRawatInap: true
    }
  },

  {
    path: '/profil-patient',
    name: 'ProfilePasien',
    props: true,
    component: () => import('../views/profile-patient/Index.vue'),
    meta: {
      auth: true,
      permissions: [Roles.ROOT, Roles.IGD, Roles.JALAN, Roles.INAP, Roles.Admin],
      showInSidebar: false,
      hasQueryParamPage: true,
      icon: 'mdi-account',
      title: 'Profile Pasien',
      isRawatJalan: true
    }
  },
  // {
  //   path: '/dashboard-pasien-inap',
  //   name: 'DashboardPasienInap',
  //   params: true,

  //   component: () => import('../views/dashboard/dashboard-pasien.vue'),
  //   meta: {
  //     auth: true,
  //     permissions: [Roles.ROOT],
  //     showInSidebar: false,
  //     hasQueryParamPage: true,

  //     icon: 'mdi-account-card',
  //     title: 'Dashboard Pasien Inap',
  //     isRawatInap: true
  //   }
  // },
  {
    path: '/resume-keperawatan-pasien-pulang-rawat-inap',
    name: 'ResumeKeperawatanPasienPulangRawatInap',
    params: true,

    component: () =>
      import('../views/rawat-inap/ResumeKeperawatanPasienPulangRawatInap.vue'),
    meta: {
      auth: true,
      permissions: [Roles.ROOT, Roles.IGD, Roles.JALAN, Roles.INAP, Roles.Admin],
      showInSidebar: false,
      hasQueryParamPage: true,
      isRawatInap: true,

      icon: 'mdi-hospital',
      title: 'R. Keperawatan Pasien Pulang'
    }
  },
  {
    path: '/resume-medis-rawat-inap',
    name: 'ResumeMedisRawatInap',
    params: true,

    component: () => import('../views/rawat-inap/ResumeMedisRawatInap.vue'),
    meta: {
      auth: true,
      permissions: [Roles.ROOT, Roles.IGD, Roles.JALAN, Roles.INAP, Roles.Admin],
      showInSidebar: false,
      icon: 'mdi-hospital',
      isRawatInap: true,
      hasQueryParamPage: true,
      title: 'R. Medis Rawat Inap'
    }
  },

  {
    path: '/kamar-operasi',
    name: 'KamarOperasi',
    params: true,

    component: () => import('../views/kamaroperasi/KamarOperasi.vue'),
    meta: {
      auth: true,
      permissions: [Roles.ROOT, Roles.IGD, Roles.JALAN, Roles.INAP, Roles.Admin],
      showInSidebar: false,
      hasQueryParamPage: true,

      icon: 'mdi-folder',
      title: 'Kamar Operasi',
      isRawatInap: true
    }
  },
  {
    path: '/partograf',
    name: 'Partograf',
    params: true,

    component: () => import('../views/rawat-inap/Partograf.vue'),
    meta: {
      auth: true,
      permissions: [Roles.ROOT],
      showInSidebar: false,
      hasQueryParamPage: true,

      icon: 'mdi-file',
      title: 'Partograf',
      isRawatInap: true
    }
  },

  // // {
  // //   path: '/form-awal-keperawatan-rawat-inap',
  // //   name: 'FormAwalKeperawatanRawatInap',
  // //   params: true,

  // //   component: () =>
  // //     import('../views/rawat-inap/FormAsesmenAwalKeperawatanRawatInap.vue'),
  // //   meta: {
  // //     auth: true,
  // //     permissions: [Roles.ROOT],
  // //     showInSidebar: false,
  // //     icon: 'mdi-clipboard-pulse-outline',
  // //     hasQueryParamPage: true,
  // //     title: 'Asesmen Keper  Awal Inap',
  // //     isRawatInap: true
  // //   }
  // // },
  // // {
  // //   path: '/form-awal-keperawatan-rawat-inap-anak',
  // //   name: 'FormAwalKeperawatanRawatInapAnak',
  // //   params: true,

  // //   component: () =>
  // //     import('../views/rawat-inap/FormAsesmenAwalKeperawatanRawatInapAnak.vue'),
  // //   meta: {
  // //     auth: true,
  // //     permissions: [Roles.ROOT],
  // //     showInSidebar: false,
  // //     icon: 'mdi-clipboard-pulse-outline',
  // //     hasQueryParamPage: true,
  // //     title: 'Asesmen Anak',
  // //     isRawatInap: true
  // //   }
  // // },
  // // {
  // //   path: '/form-awal-medis-rawat-inap-neonatus',
  // //   name: 'FormAwalMedisRawatInapNeonatus',
  // //   params: true,

  // //   component: () =>
  // //     import('../views/rawat-inap/FormAsesmenAwalMedisRawatInapNeonatus.vue'),
  // //   meta: {
  // //     auth: true,
  // //     permissions: [Roles.ROOT],
  // //     showInSidebar: false,
  // //     icon: 'mdi-clipboard-pulse-outline',
  // //     hasQueryParamPage: true,
  // //     title: 'Asesmen Neonatus',
  // //     isRawatInap: true
  // //   }
  // // },
  // // {
  // //   path: '/form-awal-keperawatan-rawat-inap-neonatus',
  // //   name: 'FormAwalKeperawatanRawatInapNeonatus',
  // //   params: true,

  // //   component: () =>
  // //     import(
  // //       '../views/rawat-inap/FormAsesmenAwalKeperawatanRawatInapNeonatus.vue'
  // //     ),
  // //   meta: {
  // //     auth: true,
  // //     permissions: [Roles.ROOT],
  // //     showInSidebar: false,
  // //     icon: 'mdi-clipboard-pulse-outline',
  // //     hasQueryParamPage: true,
  // //     title: 'Asesmen Neonatus',
  // //     isRawatInap: true
  // //   }
  // // },

  // {
  //   path: '/form-awal-keperawatan-rawat-inap-icu',
  //   name: 'FormAwalKeperawatanRawatInapIcu',
  //   params: true,

  //   component: () =>
  //     import('../views/rawat-inap/FormAsesmenAwalKeperawatanRawatInapIcu.vue'),
  //   meta: {
  //     auth: true,
  //     permissions: [Roles.ROOT],
  //     showInSidebar: false,
  //     icon: 'mdi-clipboard-pulse-outline',
  //     hasQueryParamPage: true,
  //     title: 'Asesmen ICU',
  //     isRawatInap: true
  //   }
  // },

  // {
  //   path: '/form-asesmen-awal-medis-pasien-rawat-inap-kebidanan',
  //   name: 'FormAsesmenAwalMedisPasienRawatInapKebidanan',
  //   params: true,

  //   component: () =>
  //     import('../views/rawat-inap/FormAsesmenAwalMedisPasienRawatInapKebidanan.vue'),
  //   meta: {
  //     auth: true,
  //     permissions: [Roles.ROOT],
  //     showInSidebar: false,
  //     icon: 'mdi-clipboard-pulse-outline',
  //     hasQueryParamPage: true,
  //     title: 'Form Asesmen Awal Medis Pasien Rawat Inap Kebidanan',
  //     isRawatInap: true
  //   }
  // },
  // // {
  // //   path: '/form-asesmen-lanjutan-resiko-jatuh-anak-intervensi-skala-humpty-dumpty',
  // //   name: 'FormAsesmenLanjutanResikoJatuhAnakDanIntervensiSkalaHumptyDumpty',
  // //   params: true,

  // //   component: () =>
  // //     import(
  // //       '../views/rawat-inap/FormAsesmenLanjutanResikoJatuhAnakDanIntervensiSkalaHumptyDumpty.vue'
  // //     ),
  // //   meta: {
  // //     auth: true,
  // //     permissions: [Roles.ROOT],
  // //     showInSidebar: false,
  // //     icon: 'mdi-clipboard-pulse-outline',
  // //     hasQueryParamPage: true,
  // //     title: 'Asesmen Awal Medis Pasien Rawat Inap Anak',
  // //     isRawatInap: true
  // //   }
  // // },
  // // {
  // //   path: '/form-asesmen-lanjutan-resiko-jatuh-dewasa-dan-intervensi-skala-morse',
  // //   name: 'FormAsesmenLanjutanResikoJatuhDewasaDanIntervensiSkalaMorse',
  // //   params: true,

  // //   component: () =>
  // //     import('../views/rawat-inap/FormAsesmenLanjutanResikoJatuhDewasaDanIntervensiSkalaMorse.vue'),
  // //   meta: {
  // //     auth: true,
  // //     permissions: [Roles.ROOT],
  // //     showInSidebar: false,
  // //     icon: 'mdi-clipboard-pulse-outline',
  // //     hasQueryParamPage: true,
  // //     title: 'Asesmen Lanjutan Resiko Jatuh Dewasa Dan Intervensi Skala Morse',
  // //     isRawatInap: true
  // //   }
  // // },

  // // {
  // //   path: '/form-asesmen-populasi-khusus',
  // //   name: 'FormAsesmenPopulasiKhusus',
  // //   params: true,

  // //   component: () =>
  // //     import('../views/rawat-inap/FormAsesmenPopulasiKhusus.vue'),
  // //   meta: {
  // //     auth: true,
  // //     permissions: [Roles.ROOT],
  // //     showInSidebar: false,
  // //     hasQueryParamPage: true,

  // //     icon: 'mdi-clipboard-pulse-outline',
  // //     title: 'Asesmen Populasi Khusus',
  // //     isRawatInap: true
  // //   }
  // // },
  // {
  //   path: '/asesmen-awal-keperawatan-rawat-inap-geriatri',
  //   name: 'AsesmenAwalKeperawatanRawatInapGeriatri',
  //   params: true,

  //   component: () =>
  //     import('../views/rawat-inap/AsesmenAwalKeperawatanRawatInapGeriatri.vue'),
  //   meta: {
  //     auth: true,
  //     permissions: [Roles.ROOT],
  //     showInSidebar: false,
  //     hasQueryParamPage: true,

  //     icon: 'mdi-clipboard-pulse-outline',
  //     title: 'Asesmen Geriatri',
  //     isRawatInap: true
  //   }
  // },
  // // {
  // //   path: '/implementasi-keperawatan',
  // //   name: 'ImplementasiKeperawatan',
  // //   params: true,

  // //   component: () =>
  // //     import('../views/rawat-inap/ImplementasiKeperawatan.vue'),
  // //   meta: {
  // //     auth: true,
  // //     permissions: [Roles.ROOT],
  // //     showInSidebar: false,
  // //     hasQueryParamPage: true,

  // //     icon: 'mdi-file',
  // //     title: 'Implementasi Keperawatan',
  // //     isRawatInap: true
  // //   }
  // // },
  // {
  //   path: '/rencana-asuhan-kebidanan-subdep-kebidanan-dan-kandungan',
  //   name: 'RencanaAsuhanKebidananSubdepKebidananDanKandungan',
  //   params: true,

  //   component: () =>
  //     import(
  //       '../views/rawat-inap/RencanaAsuhanKebidananSubdepKebidananDanKandungan.vue'
  //     ),
  //   meta: {
  //     auth: true,
  //     permissions: [Roles.ROOT],
  //     showInSidebar: false,
  //     hasQueryParamPage: true,

  //     icon: 'mdi-file',
  //     title: 'Rencana Asuhan Kebidanan Subdep Kebidanan Dan Kandungan',
  //     isRawatInap: true
  //   }
  // },
  // {
  //   path: '/rencana-asuhan-keperawatan',
  //   name: 'RencanaAsuhanKeperawatanAnak',
  //   params: true,

  //   component: () =>
  //     import('../views/rawat-inap/RencanaAsuhanKeperawatan.vue'),
  //   meta: {
  //     auth: true,
  //     permissions: [Roles.ROOT],
  //     showInSidebar: false,
  //     hasQueryParamPage: true,
  //     isRawatInap: true,

  //     icon: 'mdi-ambulance',
  //     title: 'Rencana Asuhan Keperawatan'
  //   }
  // },
  // {
  //   path: '/rencana-asuhan-keperawatan-anak',
  //   name: 'RencanaAsuhanKeperawatanAnak',
  //   params: true,

  //   component: () =>
  //     import('../views/rawat-inap/RencanaAsuhanKeperawatanAnak.vue'),
  //   meta: {
  //     auth: true,
  //     permissions: [Roles.ROOT],
  //     showInSidebar: false,
  //     hasQueryParamPage: true,
  //     isRawatInap: true,

  //     icon: 'mdi-file',
  //     title: 'Rencana Asuhan Keperawatan Anak'
  //   }
  // },
  // {
  //   path: '/form-rencana-asuhan-keperawatan-bayi-neonatus',
  //   name: 'FormRencanaAsuhanKeperawatanBayiNeonatus',
  //   params: true,

  //   component: () =>
  //     import(
  //       '../views/rawat-inap/FormRencanaAsuhanKeperawatanBayiNeonatus.vue'
  //     ),
  //   meta: {
  //     auth: true,
  //     permissions: [Roles.ROOT],
  //     showInSidebar: false,
  //     icon: 'mdi-file',
  //     hasQueryParamPage: true,
  //     title: 'Rencana Asuhan K.Bayi Neonatus',
  //     isRawatInap: true
  //   }
  // },
  // // {
  // //   path: '/grafik-bayi',
  // //   name: 'GrafikBayi',
  // //   params: true,

  // //   component: () => import('../views/rawat-inap/GrafikBayi.vue'),
  // //   meta: {
  // //     auth: true,
  // //     permissions: [Roles.ROOT],
  // //     showInSidebar: false,
  // //     hasQueryParamPage: true,

  // //     icon: 'mdi-baby',
  // //     title: 'Grafik Bayi',
  // //     isRawatInap: true
  // //   }
  // // },
  // // {
  // //   path: '/transfer-pasien',
  // //   name: 'TransferPasien',
  // //   params: true,

  // //   component: () => import('../views/rawat-inap/TransferPasien.vue'),
  // //   meta: {
  // //     auth: true,
  // //     permissions: [Roles.ROOT],
  // //     showInSidebar: false,
  // //     hasQueryParamPage: true,

  // //     icon: 'mdi-transfer',
  // //     title: 'Transfer Pasien',
  // //     isRawatInap: true
  // //   }
  // // },
  // // {
  // //   path: '/observasi-khusus',
  // //   name: 'ObservasiKhusus',
  // //   params: true,

  // //   component: () => import('../views/rawat-inap/ObservasiKhusus.vue'),
  // //   meta: {
  // //     auth: true,
  // //     permissions: [Roles.ROOT],
  // //     showInSidebar: false,
  // //     hasQueryParamPage: true,

  // //     icon: 'mdi-card-search',
  // //     title: 'Observasi Khusus',
  // //     isRawatInap: true
  // //   }
  // // },
  {
    path: '/laporan-tindakan',
    name: 'LaporanTindakan',
    params: true,

    component: () => import('../views/rawat-inap/LaporanTindakan.vue'),
    meta: {
      auth: true,
      permissions: [Roles.ROOT, Roles.IGD, Roles.JALAN, Roles.INAP, Roles.Admin],
      showInSidebar: false,
      hasQueryParamPage: true,

      icon: 'mdi-file',
      title: 'Laporan Tindakan',
      isRawatInap: true
    }
  },
  {
    path: '/asesmen-pre-induksi-dan-pemantauan-anestesi-sedasi',
    name: 'AsesmenPreInduksiDanPemantauanAnestesiSedasi',
    params: true,

    component: () =>
      import('../views/rawat-inap/AsesmenPreInduksiDanPemantauanAnestesiSedasi.vue'),
    meta: {
      auth: true,
      permissions: [Roles.ROOT],
      showInSidebar: false,
      hasQueryParamPage: true,

      icon: 'mdi-file',
      title: 'Asesmen Pre Induksi Dan Pemantauan Anestesi / Sedasi',
      isRawatInap: true
    }
  },
  // {
  //   path: '/form-tindakan-operasi',
  //   name: 'FormTindakanOperasi',
  //   params: true,

  //   component: () =>
  //     import('../views/rawat-inap/FormTindakanOperasi.vue'),
  //   meta: {
  //     auth: true,
  //     permissions: [Roles.ROOT],
  //     showInSidebar: false,
  //     hasQueryParamPage: true,

  //     icon: 'mdi-file',
  //     title: 'Form Tindakan Operasi',
  //     isRawatInap: true
  //   }
  // },
  {
    path: '/pemantauan-anestesi-lokal',
    name: 'PemantauanAnestesiLokal',
    params: true,

    component: () => import('../views/rawat-inap/PemantauanAnestesiLokal.vue'),
    meta: {
      auth: true,
      permissions: [Roles.ROOT],
      showInSidebar: false,
      hasQueryParamPage: true,

      icon: 'mdi-needle',
      title: 'Pemantauan Anestesi Lokal',
      isRawatInap: true
    }
  },
  // {
  //   path: '/pengkajian-awal-pasien-terminal',
  //   name: 'PengkajianAwalPasienTerminal',
  //   params: true,

  //   component: () =>
  //     import(
  //       '../views/rawat-inap/PengkajianAwalPasienTerminalMedisDanKeperawatan.vue'
  //     ),
  //   meta: {
  //     auth: true,
  //     permissions: [Roles.ROOT],
  //     showInSidebar: false,
  //     hasQueryParamPage: true,

  //     icon: 'mdi-file-arrow-up-down-outline',
  //     title: 'Pengkajian Awal Pasien Terminal Medis Dan Keperawatan',
  //     isRawatInap: true
  //   }
  // },
  // {
  //   path: '/pengkajian-lanjutan-pasien-terminal',
  //   name: 'PengkajianLanjutanPasienTerminal',
  //   params: true,

  //   component: () =>
  //     import('../views/rawat-inap/PengkajianLanjutanPasienTerminal.vue'),
  //   meta: {
  //     auth: true,
  //     permissions: [Roles.ROOT],
  //     showInSidebar: false,
  //     hasQueryParamPage: true,

  //     icon: 'mdi-file-arrow-up-down-outline',
  //     title: 'Pengkajian Lanjutan Pasien Terminal',
  //     isRawatInap: true
  //   }
  // },

  // {
  //   path: '/laporan-tindakan',
  //   name: 'LaporanTindakan',
  //   params: true,

  //   component: () => import('../views/rawat-inap/LaporanTindakan.vue'),
  //   meta: {
  //     auth: true,
  //     permissions: [Roles.ROOT],
  //     showInSidebar: false,
  //     icon: 'mdi-file',
  //     isRawatInap: true,
  //     hasQueryParamPage: true,
  //     title: 'Laporan Tindakan'
  //   }
  // },
  // {
  //   path: '/formulir-transfer-pasien',
  //   name: 'FormulirTransferPasien',
  //   params: true,

  //   component: () => import('../views/formulir/FormulirTransferPasien.vue'),
  //   meta: {
  //     auth: true,
  //     permissions: [Roles.ROOT],
  //     showInSidebar: false,
  //     icon: 'mdi-file',
  //     isRawatInap: true,
  //     hasQueryParamPage: true,
  //     title: 'Form Transfer Pasien'
  //   }
  // },

  // {
  //   path: '/form-checklist-pasien-masuk-rawat-intensif',
  //   name: 'FormChecklistPasienMasukRawatIntensif',
  //   params: true,

  //   component: () =>
  //     import('../views/rawat-inap/FormChecklistPasienMasukRawatIntensif.vue'),
  //   meta: {
  //     auth: true,
  //     permissions: [Roles.ROOT],
  //     showInSidebar: false,
  //     icon: 'mdi-clipboard-pulse-outline',
  //     hasQueryParamPage: true,
  //     title: 'Checklist Pasien Masuk Rawat Intensif',
  //     isRawatInap: true
  //   }
  // },
  // {
  //   path: '/surat-pengantar-rawat-inap',
  //   name: 'SuratPengantarRawatInap',
  //   params: true,

  //   component: () =>
  //     import('../views/rawat-inap/SuratPengantarRawatInap.vue'),
  //   meta: {
  //     auth: true,
  //     permissions: [Roles.ROOT],
  //     showInSidebar: false,
  //     hasQueryParamPage: true,

  //     icon: 'mdi-file',
  //     title: 'Surat Pengantar Rawat Inap',
  //     isRawatInap: true
  //   }
  // },
  {
    path: '/pengkajian-pre-anestesi-pre-sedasi',
    name: 'PengakajianPreAnestesiPreSedasi',
    params: true,

    component: () =>
      import('../views/rawat-inap/PengakajianPreAnestesiPreSedasi.vue'),
    meta: {
      auth: true,
      permissions: [Roles.ROOT, Roles.IGD, Roles.JALAN, Roles.INAP, Roles.Admin],
      showInSidebar: false,
      hasQueryParamPage: true,

      icon: 'mdi-file',
      title: 'Pengakajian Pre Anestesi / Pre Sedasi',
      isRawatInap: true
    }
  },
  // {
  //   path: '/surat-keterangan-pasien-sudah-meninggal',
  //   name: 'SuratKeteranganPasienSudahMeninggal',
  //   params: true,

  //   component: () =>
  //     import('../views/rawat-inap/SuratKeteranganPasienSudahMeninggal.vue'),
  //   meta: {
  //     auth: true,
  //     permissions: [Roles.ROOT],
  //     showInSidebar: false,
  //     hasQueryParamPage: true,

  //     icon: 'mdi-file',
  //     title: 'Surat Keterangan Pasien Sudah Meninggal',
  //     isRawatInap: true
  //   }
  // },
  // {
  //   path: '/form-awal-keperawatan-rawat-inap-icu',
  //   name: 'FormAwalKeperawatanRawatInapIcu',
  //   params: true,

  //   component: () =>
  //     import('../views/rawat-inap/FormAsesmenAwalKeperawatanRawatInapIcu.vue'),
  //   meta: {
  //     auth: true,
  //     permissions: [Roles.ROOT],
  //     showInSidebar: false,
  //     icon: 'mdi-clipboard-pulse-outline',
  //     hasQueryParamPage: true,
  //     title: 'Awal K.Rawat Inap ICU',
  //     isRawatInap: true
  //   }
  // },
  // {
  //   path: '/form-asesmen-triage',
  //   name: 'form-asesmen-triage',
  //   component: () => import('../views/FormAsesmenTriage.vue'),
  //   meta: {
  //     auth: true,
  //     permissions: [Roles.ROOT],
  //     showInSidebar: false,
  //     icon: 'mdi-file',
  //     hasQueryParamPage: true,

  //     title: 'Triage',
  //     isRawatJalan: true
  //   }
  // },
  {
    path: '/form-rawat-jalan',
    name: 'FormRawatJalan',
    params: true,
    // query: {
    //   // no_rm: VueRouter.route.query.no_rm
    //   // folio_id: this.$route.query.no_rm,
    //   // registration_id: this.$route.query.no_rm
    // },
    component: () => import('../views/FormRawatJalan.vue'),
    meta: {
      auth: true,
      permissions: [Roles.ROOT, Roles.IGD, Roles.JALAN, Roles.INAP, Roles.Admin],
      showInSidebar: false,
      icon: 'mdi-file',
      hasQueryParamPage: true,
      title: 'Asesmen',
      isRawatJalan: true
    }
  },
  {
    path: '/asesmen-antenatal-care',
    name: 'AsesmenAntenatalCare',
    params: true,

    component: () =>
      import('../views/rawat-inap/AsesmenAntenatalCare.vue'),
    meta: {
      auth: true,
      permissions: [Roles.ROOT, Roles.IGD, Roles.JALAN, Roles.INAP, Roles.Admin],
      showInSidebar: false,
      hasQueryParamPage: true,

      icon: 'mdi-file',
      title: 'Antenatal Care',
      isRawatJalan: true
    }
  },

  {
    path: '/form-pasien',
    name: 'FormPasien',
    params: true,

    component: () =>
      import('../views/rawat-inap/FormPasien.vue'),
    meta: {
      auth: true,
      permissions: [Roles.ROOT, Roles.IGD, Roles.JALAN, Roles.INAP, Roles.Admin],
      showInSidebar: false,
      hasQueryParamPage: true,

      icon: 'mdi-file',
      title: 'Gizi',
      isRawatInap: true
    }
  },
  {
    path: '/pnc',
    name: 'PNC',
    params: true,

    component: () =>
      import('../views/rawat-inap/PNC.vue'),
    meta: {
      auth: true,
      permissions: [Roles.ROOT, Roles.IGD, Roles.JALAN, Roles.INAP, Roles.Admin],
      showInSidebar: false,
      hasQueryParamPage: true,

      icon: 'mdi-file',
      title: 'PNC',
      isRawatInap: true
    }
  },
  // {
  //   path: '/surat-rujukan',
  //   name: 'SuratRujukan',
  //   params: true,

  //   component: () =>
  //     import('../views/rawat-inap/SuratRujukan.vue'),
  //   meta: {
  //     auth: true,
  //     permissions: [Roles.ROOT],
  //     showInSidebar: false,
  //     hasQueryParamPage: true,

  //     icon: 'mdi-file',
  //     title: 'Surat Rujukan',
  //     isRawatInap: true
  //   }
  // },

  {
    path: '/penunjang',
    name: 'Penunjang',
    params: true,
    // query: {
    //   // no_rm: VueRouter.route.query.no_rm
    //   // folio_id: this.$route.query.no_rm,
    //   // registration_id: this.$route.query.no_rm
    // },
    component: () => import('../views/penunjang/Penunjang.vue'),
    meta: {
      auth: true,
      permissions: [Roles.ROOT],
      showInSidebar: false,
      icon: 'mdi-file',
      hasQueryParamPage: true,
      title: 'Penunjang',
      isRawatJalan: true
    }
  },
  {
    path: '/resep',
    name: 'HistoryResep',
    params: true,
    // query: {
    //   // no_rm: VueRouter.route.query.no_rm
    //   // folio_id: this.$route.query.no_rm,
    //   // registration_id: this.$route.query.no_rm
    // },
    component: () => import('../views/resep/Resep.vue'),
    meta: {
      auth: true,
      permissions: [Roles.ROOT, Roles.IGD, Roles.JALAN, Roles.INAP, Roles.Admin],
      showInSidebar: false,
      icon: 'mdi-pill',
      hasQueryParamPage: true,
      title: 'E-Resep',
      isRawatJalan: true
    }
  },
  {
    path: '/okupasi',
    name: 'Okupasi',
    params: true,
    // query: {
    //   // no_rm: VueRouter.route.query.no_rm
    //   // folio_id: this.$route.query.no_rm,
    //   // registration_id: this.$route.query.no_rm
    // },
    component: () => import('../views/okupasi/Okupasi.vue'),
    meta: {
      auth: true,
      permissions: [Roles.ROOT, Roles.IGD, Roles.JALAN, Roles.INAP, Roles.Admin],
      showInSidebar: false,
      icon: 'mdi-doctor',
      hasQueryParamPage: true,
      title: 'Okupasi',
      isRawatJalan: true
    }
  },
  {
    path: '/okupasi-igd',
    name: 'Okupasi',
    params: true,
    // query: {
    //   // no_rm: VueRouter.route.query.no_rm
    //   // folio_id: this.$route.query.no_rm,
    //   // registration_id: this.$route.query.no_rm
    // },
    component: () => import('../views/okupasi/Okupasi.vue'),
    meta: {
      auth: true,
      permissions: [Roles.ROOT, Roles.IGD, Roles.JALAN, Roles.INAP, Roles.Admin],
      showInSidebar: false,
      icon: 'mdi-doctor',
      hasQueryParamPage: true,
      title: 'Okupasi',
      isIGD: true
    }
  },
  {
    path: '/okupasi-rawat-inap',
    name: 'OkupasiRawatInap',
    params: true,
    // query: {
    //   // no_rm: VueRouter.route.query.no_rm
    //   // folio_id: this.$route.query.no_rm,
    //   // registration_id: this.$route.query.no_rm
    // },
    component: () => import('../views/okupasi/Okupasi.vue'),
    meta: {
      auth: true,
      permissions: [Roles.ROOT, Roles.IGD, Roles.JALAN, Roles.INAP, Roles.Admin],
      showInSidebar: false,
      icon: 'mdi-doctor',
      hasQueryParamPage: true,
      title: 'Okupasi',
      isRawatInap: true
    }
  },

  {
    path: '/surat-sakit',
    name: 'SuratSakit',
    params: true,
    // query: {
    //   // no_rm: VueRouter.route.query.no_rm
    //   // folio_id: this.$route.query.no_rm,
    //   // registration_id: this.$route.query.no_rm
    // },
    component: () => import('../views/suratsakit/SuratSakit.vue'),
    meta: {
      auth: true,
      permissions: [Roles.ROOT, Roles.IGD, Roles.JALAN, Roles.INAP, Roles.Admin],
      showInSidebar: false,
      icon: 'mdi-file',
      hasQueryParamPage: true,
      title: 'Surat Sakit',
      isRawatJalan: true
    }
  },

  {
    path: '/hemodialisa',
    name: 'Hemodialisa',
    params: true,
    // query: {
    //   // no_rm: VueRouter.route.query.no_rm
    //   // folio_id: this.$route.query.no_rm,
    //   // registration_id: this.$route.query.no_rm
    // },
    component: () => import('../views/Hd/MainHd.vue'),
    meta: {
      auth: true,
      permissions: [Roles.ROOT, Roles.IGD, Roles.JALAN, Roles.INAP, Roles.Admin],
      showInSidebar: false,
      icon: 'mdi-replay',
      hasQueryParamPage: true,
      title: 'Hemodialisa',
      isRawatJalan: true
    }
  },
  {
    path: '/echocardiography',
    name: 'Echocardiography',
    component: () => import('../views/echocardiography/Echo.vue'),
    meta: {
      auth: true,
      permissions: [Roles.ROOT, Roles.IGD, Roles.JALAN, Roles.INAP, Roles.Admin],
      showInSidebar: false,
      icon: 'mdi-pulse',
      title: 'Echocardiography',
      isRawatJalan: true
    }
  },
  {
    path: '/treadmill',
    name: 'Treadmill',
    component: () => import('../views/treadmill/Treadmill.vue'),
    meta: {
      auth: true,
      permissions: [Roles.ROOT, Roles.IGD, Roles.JALAN, Roles.INAP, Roles.Admin],
      showInSidebar: false,
      icon: 'mdi-heart',
      title: 'Treadmill',
      isRawatJalan: true
    }
  },
  // {
  //   path: '/cppt',
  //   name: 'CPPT',
  //   component: () => import('../views/cppt/index.vue'),
  //   meta: {
  //     auth: true,
  //     permissions: [Roles.ROOT],
  //     showInSidebar: true,
  //     icon: 'mdi-file',
  //     title: 'CPPT Poliklinik'
  //   }
  // },
  {
    path: '/rekam-medik-kedokteran-gigi',
    name: 'rekam-medik-kedokteran-gigi',
    component: () =>
      import('../views/form-gigi/rekam-medik-kedokteran-gigi.vue'),
    meta: {
      auth: true,
      permissions: [Roles.ROOT, Roles.IGD, Roles.JALAN, Roles.INAP, Roles.Admin],
      showInSidebar: false,
      icon: 'mdi-tooth',
      title: 'Form Gigi',
      isRawatJalan: true
    }
  },
  // {
  //   path: '/lembar-konsultasi',
  //   name: 'lembar-konsultasi',
  //   component: () => import('../views/lembar-konsultasi/lembar-konsultasi.vue'),
  //   meta: {
  //     auth: true,
  //     permissions: [Roles.ROOT],
  //     showInSidebar: false,
  //     icon: 'mdi-face-man',
  //     title: 'Lembar Konsultasi',
  //     isRawatJalan: true
  //   }
  // },

  {
    path: '/rehab-medis',
    name: 'RehabMedik',
    component: () =>
      import('../views/formulir-klaim-rawat-jalan/RehabMedik.vue'),
    meta: {
      auth: true,
      permissions: [Roles.ROOT, Roles.IGD, Roles.JALAN, Roles.INAP, Roles.Admin],
      showInSidebar: false,
      icon: 'mdi-account-injury-outline',
      title: 'Rehab & Fisioterapi',
      isRawatJalan: true
    }
  },
  {
    path: '/monitoring-pasca-anesti-dan-sedasi',
    name: 'MonitoringPascaAnestiDanSedasi',
    params: true,

    component: () =>
      import('../views/rawat-inap/MonitoringPascaAnestiDanSedasi.vue'),
    meta: {
      auth: true,
      permissions: [Roles.ROOT],
      showInSidebar: false,
      hasQueryParamPage: true,

      icon: 'mdi-file',
      title: 'Monitoring Pasca Anesti Dan Sedasi',
      isRawatInap: true
    }
  },
  // {
  //   path: '/ringkasan-masuk-dan-keluar',
  //   name: 'RingkasanMasukDanKeluar',
  //   params: true,

  //   component: () =>
  //     import('../views/rawat-inap/RingkasanMasukDanKeluar.vue'),
  //   meta: {
  //     auth: true,
  //     permissions: [Roles.ROOT],
  //     showInSidebar: false,
  //     hasQueryParamPage: true,

  //     icon: 'mdi-file',
  //     title: 'Ringkasan Masuk Dan Keluar',
  //     isRawatInap: true
  //   }
  // },
  // {
  //   path: '/observasi-ews',
  //   name: 'ObservasiEws',
  //   params: true,

  //   component: () =>
  //     import('../views/rawat-inap/ObservasiEws.vue'),
  //   meta: {
  //     auth: true,
  //     permissions: [Roles.ROOT],
  //     showInSidebar: false,
  //     hasQueryParamPage: true,

  //     icon: 'mdi-file',
  //     title: 'Observasi Ews',
  //     isRawatInap: true
  //   }
  // },
  // {
  //   path: '/pediatri-ews',
  //   name: 'PediatriEws',
  //   params: true,

  //   component: () =>
  //     import('../views/rawat-inap/PediatriEws.vue'),
  //   meta: {
  //     auth: true,
  //     permissions: [Roles.ROOT],
  //     showInSidebar: false,
  //     hasQueryParamPage: true,

  //     icon: 'mdi-file',
  //     title: 'Pediatri Ews',
  //     isRawatInap: true
  //   }
  // },
  // {
  //   path: '/keseimbangan-cairan-24-jam',
  //   name: 'KeseimbanganCairan24Jam',
  //   params: true,

  //   component: () =>
  //     import('../views/rawat-inap/KeseimbanganCairan24Jam.vue'),
  //   meta: {
  //     auth: true,
  //     permissions: [Roles.ROOT],
  //     showInSidebar: false,
  //     hasQueryParamPage: true,

  //     icon: 'mdi-file',
  //     title: 'Keseimbangan Cairan 24 Jam',
  //     isRawatInap: true
  //   }
  // },
  // {
  //   path: '/form-rehab',
  //   // name: 'contoh-menu',
  //   component: () => import('../components/InheritMenu.vue'),
  //   meta: {
  //     auth: true,
  //     showInSidebar: true,
  //     icon: 'mdi-library',
  //     title: 'Rehab Medik'
  //   },
  //   children: [
  //     {
  //       path: 'klaim-rawat-jalan',
  //       name: 'KlaimRawatJalan',
  //       component: () =>
  //         import(
  //           '../views/formulir-klaim-rawat-jalan/formulir-klaim-rawat-jalan.vue'
  //         ),
  //       meta: {
  //         auth: true,
  //         showInSidebar: true,
  //         icon: 'mdi-minus',
  //         title: 'Formulir Klaim Rawat Jalan'
  //       }
  //     },
  //     {
  //       path: 'lembar-program-terapi',
  //       name: 'LembarProgramTerapi',
  //       component: () =>
  //         import('../views/rehabmedik/lembar-program-terapi.vue'),
  //       meta: {
  //         auth: true,
  //         showInSidebar: true,
  //         icon: 'mdi-minus',
  //         title: 'Lembar Program Terapi'
  //       }
  //     },
  //     {
  //       path: 'lembar-hasil-tindakan-uji-fungsi',
  //       name: 'LembarHasilTindakanUjiFungsi',
  //       component: () =>
  //         import('../views/rehabmedik/lembar-hasil-tindakan.vue'),
  //       meta: {
  //         auth: true,
  //         showInSidebar: true,
  //         icon: 'mdi-minus',
  //         title: 'Lembar Tindakan Uji Fungsi'
  //       }
  //     }
  //   ]
  // },

  // {
  //   path: '/formulir-klaim-rawat-jalan',
  //   name: 'formulir-klaim-rawat-jalan',
  //   component: () => import('../views/formulir-klaim-rawat-jalan/formulir-klaim-rawat-jalan.vue'),
  //   meta: {
  //     auth: true,
  //     permissions: [Roles.ROOT],
  //     showInSidebar: true,
  //     icon: 'mdi-file',
  //     title: 'Formulir Klaim Rawat Jalan'
  //   }
  // },

  // {
  //   path: '/form-asesmen-triage',
  //   name: 'form-asesmen-triage',
  //   component: () => import('../views/FormAsesmenTriage.vue'),
  //   meta: {
  //     auth: true,
  //     permissions: [Roles.ROOT],
  //     showInSidebar: false,
  //     icon: 'mdi-file',
  //     hasQueryParamPage: true,

  //     title: 'Asesmen Triage',
  //     isRawatJalan: true
  //   }
  // },
  // {
  //   path: '/form-uji-coba',
  //   name: 'form-uji-coba',
  //   component: () => import('../views/form-uji.vue'),
  //   meta: {
  //     auth: true,
  //     permissions: [Roles.Perawat],
  //     showInSidebar: true,
  //     icon: 'mdi-file',
  //     title: 'Uji Coba'
  //   }
  // },
  // {
  //   path: '/order-penunjang-lab',
  //   name: 'order-penunjang-lab',
  //   component: () => import('../views/order/order-penunjang.vue'),
  //   meta: {
  //     auth: true,
  //     permissions: [Roles.ROOT],
  //     showInSidebar: true,
  //     icon: 'mdi-beaker-plus-outline',
  //     title: 'Order Penunjang'
  //   }
  // },
  // {
  //   path: '/order-resep',
  //   name: 'order-resep',
  //   component: () => import('../views/order/order-resep.vue'),
  //   meta: {
  //     auth: true,
  //     permissions: [Roles.ROOT],
  //     showInSidebar: true,
  //     icon: 'mdi-pill',
  //     title: 'Order Resep'
  //   }
  // },

  // {
  //   path: '/form-mcu',
  //   // name: 'contoh-menu',
  //   component: () => import('../components/InheritMenu.vue'),
  //   meta: {
  //     auth: true,
  //     showInSidebar: true,
  //     icon: 'mdi-list-status',
  //     title: 'MCU'
  //   },
  //   children: [
  //     {
  //       path: 'pemeriksaan-fisik',
  //       name: 'PemeriksaanFisik',
  //       component: () => import('../views/form-mcu/pemeriksaan-fisik.vue'),
  //       meta: {
  //         auth: true,
  //         showInSidebar: true,
  //         icon: 'mdi-minus',
  //         title: 'Pemeriksaan Fisik'
  //       }
  //     },
  //     {
  //       path: 'pemeriksaan-cffd-mcu',
  //       name: 'PemeriksaanFisik',
  //       component: () => import('../views/form-mcu/pemeriksaan-fisik.vue'),
  //       meta: {
  //         auth: true,
  //         showInSidebar: true,
  //         icon: 'mdi-minus',
  //         title: 'List Pemeriksaan CFFD'
  //       }
  //     },
  //     {
  //       path: 'permintaan-evaluasi-kelaikan-kerja',
  //       name: 'PemeriksaanEvaluasiKelaikanKerja',
  //       component: () => import('../views/form-mcu/pemeriksaan-fisik.vue'),
  //       meta: {
  //         auth: true,
  //         showInSidebar: true,
  //         icon: 'mdi-minus',
  //         title: 'Evaluasi Kelaikan Kerja'
  //       }
  //     },
  //     {
  //       path: 'permintaan-evaluasi-kelaikan-kerja',
  //       name: 'PemeriksaanEvaluasiKelaikanKerja',
  //       component: () => import('../views/form-mcu/pemeriksaan-fisik.vue'),
  //       meta: {
  //         auth: true,
  //         showInSidebar: true,
  //         icon: 'mdi-minus',
  //         title: 'Persetujuan Membuka Informasi'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/form-gigi',
  //   // name: 'contoh-menu',
  //   component: () => import('../components/InheritMenu.vue'),
  //   meta: {
  //     auth: true,
  //     showInSidebar: true,
  //     icon: 'mdi-tooth',
  //     title: 'Form Gigi'
  //   },
  //   children: [
  //     {
  //       path: 'rekam-medik-kedokteran-gigi',
  //       name: 'rekam-medik-kedokteran-gigi',
  //       component: () => import('../views/form-gigi/rekam-medik-kedokteran-gigi.vue'),
  //       meta: {
  //         auth: true,
  //         showInSidebar: true,
  //         icon: 'mdi-minus',
  //         title: 'Rekam Medik Kedokteran Gigi'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/asesmen-kedokteran-dan-rehabilitas-medis',
  //   name: 'asesmen-kedokteran-dan-rehabilitas-medis',
  //   component: () => import('../views/asesmen-kedokteran/asesmen-kedokteran-dan-rehabilitas-medis.vue'),
  //   meta: {
  //     auth: true,
  //     showInSidebar: true,
  //     icon: 'mdi-doctor',
  //     title: 'Fisio & RHB'
  //   }
  // },

  // {
  //   path: '/pemantauan-hd',
  //   name: 'PemantauanHD',
  //   component: () => import('../views/pemantauan-hd/pemantauan-hd.vue'),
  //   meta: {
  //     auth: true,
  //     showInSidebar: true,
  //     icon: 'mdi-bottle-tonic-plus',
  //     title: 'Hemodialisa'
  //   }
  // },
  // {
  //   path: '/form-gigi',
  //   // name: 'contoh-menu',
  //   component: () => import('../components/InheritMenu.vue'),
  //   meta: {
  //     auth: true,
  //     showInSidebar: true,
  //     icon: 'mdi-ocr',
  //     title: 'Kamar Operasi'
  //   },
  //   children: [
  //     {
  //       path: 'Jadwal Operasi',
  //       name: 'rekam-medik-kedokteran-gigi',
  //       component: () => import('../views/form-gigi/rekam-medik-kedokteran-gigi.vue'),
  //       meta: {
  //         auth: true,
  //         showInSidebar: true,
  //         icon: 'mdi-minus',
  //         title: 'Jadwal Operasi'
  //       }
  //     },
  //     {
  //       path: 'rekam-medik-kedokteran-gigi',
  //       name: 'rekam-medik-kedokteran-gigi',
  //       component: () => import('../views/form-gigi/rekam-medik-kedokteran-gigi.vue'),
  //       meta: {
  //         auth: true,
  //         showInSidebar: true,
  //         icon: 'mdi-minus',
  //         title: 'Informasi Section Caesaria'
  //       }
  //     },
  //     {
  //       path: 'rekam-medik-kedokteran-gigi',
  //       name: 'rekam-medik-kedokteran-gigi',
  //       component: () => import('../views/form-gigi/rekam-medik-kedokteran-gigi.vue'),
  //       meta: {
  //         auth: true,
  //         permission: [],
  //         showInSidebar: true,
  //         icon: 'mdi-minus',
  //         title: 'Pernyataan Informasi Pembiusaan'
  //       }
  //     }
  //   ]
  // },
  {
    path: '/slip-pembayaran-kamar-bedah',
    name: 'SlipPembayaranKamarBedah',
    params: true,

    component: () =>
      import('../views/rawat-inap/SlipPembayaranKamarBedah.vue'),
    meta: {
      auth: true,
      permissions: [Roles.ROOT, Roles.IGD, Roles.JALAN, Roles.INAP, Roles.Admin],
      showInSidebar: false,
      hasQueryParamPage: true,

      icon: 'mdi-human-walker',
      title: 'Slip Pembayaran Kamar Bedah',
      isRawatInap: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      guest: true,
      title: 'Login'
    }
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('../views/Home.vue'),
    meta: {
      guest: true,
      title: '-'

    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/ex',
    name: 'Ex',
    component: () => import('../views/ex/External.vue'),
    meta: {
      guest: true,
      title: 'Ex'
    }
  },
  {
    path: '/testodo',
    name: 'testodo',
    component: () => import('../views/testodontogram.vue'),
    meta: {
      auth: false
    }
  },
  {
    path: '/panggil-pasien',
    name: 'PANGGILPASIEN',
    component: () => import('../views/panggilpasien.vue'),
    meta: {
      auth: false,
      title: 'Panggil Pasien'

    }
  },
  {
    path: '/layar-farmasi',
    name: 'LAYARFARMASI',
    component: () => import('../views/layarfarmasi.vue'),
    meta: {
      auth: false,
      title: 'Layar Farmasi'

    }
  },
  {
    path: '/display-farmasi',
    name: 'DISPLAYFARMASI',
    component: () => import('../views/farmasidisplay.vue'),
    meta: {
      auth: false,
      title: 'Display Farmasi'

    }
  }
]
const vueRouter = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    document.getElementById('app').scrollIntoView()
  }
})
// eslint-disable-next-line no-unused-vars
function hasQueryParams (route) {
  return !!Object.keys(route.query).length
}

// const role = localStorage.getItem('role')
// const perm = ['JALAN', 'INAP', 'PERAWAT']

// store.commit('set_perm', perm)
store.commit('set_perm', [Roles.ROOT, Roles.IGD, Roles.JALAN, Roles.INAP, Roles.Admin])

Vue.mixin({
  methods: {
    can: (permissions, condition = true) => {
      const perm = store.getters.perm // Ambil izin dari store.getters
      for (const permission of permissions) {
        if (perm.includes(permission)) {
          return true // Mengembalikan true jika salah satu izin terpenuhi
        }
      }
      return false
    },
    showMenus: (permissions, perm) => {
      // console.log(perm + '+----')
      let counter = 0
      if (!permissions) {
        return true
      }
      for (const permission of permissions) {
        // console.log(permissions)

        if (perm.includes(permission)) {
          counter++
        }
      }
      // console.log('showMenu permissions.length => ', permissions.length, '; counter => ', counter)
      if (permissions.length === counter) {
        return true
      }
      return false
    }
  }
})

vueRouter.beforeEach((to, from, next) => {
  document.title = to.meta?.title + ' | EMR SYAFIRA'
  if (to.path !== from.path) {
    store.commit('set_contents', []) // reset contents right sidebar
  }

  // if (to.name === 'FormRawatJalan' && !to.query.hasOwnProperty('size')) {
  //   to.query.size = '10'
  // }
  // if (to.path === 'FormRawatJalan') {
  //   // to.query.no_rm = '10'
  // }
  if (to.matched.some((record) => record.meta.auth)) {
    const auth = store.getters.isLoggedIn && store.getters.user
    if (!auth) {
      next('/login')
    } else {
      // console.log('store.getters.perm => ', store.getters.perm)

      // const perm = JSON.parse(role)

      // eslint-disable-next-line no-undef
      // $.get(baseUrlV2 + )
      const perm = store.getters.perm || []
      if (!isEmpty(perm)) {
        if (
          to.matched.some((record) => {
            return showMenuBase(record.meta.permissions ?? [], perm)
          })
        ) {
          next()
        } else {
          next({ name: 'not_found' })
        }
      } else {
        next({ name: 'not_found' })
      }
    }

    // let updatedNext = null

    // if (!Object.prototype.hasOwnProperty.call(to.query, 'page')) {
    //   updatedNext = {
    //     ...to,
    //     query: {
    //       // This line is used to retain other query if there is any
    //       ...to.query,
    //       no_rm: to.query.no_rm
    //     }
    //   }
    // }

    // if (updatedNext) {
    //   next(updatedNext)
    //   return
    // }
  }

  if (to.matched.some((record) => record.meta.guest)) {
    if (!store.getters.isLoggedIn) {
      next()
      return
    }
    next('/')
  }

  if (!hasQueryParams(to) && hasQueryParams(from) && to.name !== 'rawat-inap') {
    next({ name: to.name, query: from.query })
  } else {
    next()
  }

  // if (to.matched.some((record) => (record.meta.hasQueryParamPage))) {

  // }

  next()
})

export const router = vueRouter
// router.beforeEach((to, from) => {
//   if (to.name === 'FormRawatJalan') {
//     to.query.size = '10'
//   }
// })
export const menus = [].concat([{ subheader: 'Form Rekam Medis' }], routes)
