import axios from 'axios'
import { removeHeaderToken, setHeaderToken } from '../utils/auth'
import Cookies from 'js-cookie'

export default {
  state: {
    user: null,
    isLoggedIn: false,
    perm: []
  },
  mutations: {
    set_user (state, data) {
      state.user = data
      state.isLoggedIn = true
    },
    reset_user (state) {
      state.user = null
      state.isLoggedIn = false
    },
    set_perm (state, payload) {
      state.perm = payload
    }
  },
  getters: {
    isLoggedIn (state) {
      return state.isLoggedIn
    },
    user (state) {
      return state.user
    },
    perm: state => {
      return state.perm
    }
  },
  actions: {
    refreshAuth ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/auth/refresh')
          .then((response) => {
            const { data, status } = response
            const { results } = data

            if (status === 200) {
              commit('set_perm', results)
              resolve(results)
            } else {
              resolve([])
            }
          })
          .catch((error) => {
            console.log(error)
            resolve([])
          })
      })
    },
    login ({ dispatch, commit }, data) {
      return new Promise((resolve, reject) => {
        /* Initialize the form data */
        const formData = new FormData()

        /* Add the form data we need to submit */
        Object.keys(data).forEach(d => {
          formData.append(d, data[d])
        })
        axios.post('login/index', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(response => {
            const { con, results } = response.data
            if (con) {
              const token = results
              localStorage.setItem('token', token)
              localStorage.setItem('user', JSON.stringify(results))
              // localStorage.setItem('role', JSON.stringify(results.role))
              localStorage.setItem('isLoggedIn', true)
              Cookies.set('auth_token', token, { expires: 1 / (24 * 60) })

              setHeaderToken(token)
              commit('set_user', results)
              // commit('set_perm', results.role)

              resolve(response)
            } else {
              reject(response)
            }
          })
          .catch(err => {
            commit('reset_user')
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            localStorage.removeItem('role')
            Cookies.remove('auth_token')

            localStorage.removeItem('isLoggedIn')
            reject(err)
          })
      })
    },
    register ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('register', data)
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            commit('reset_user')
            reject(err)
          })
      })
    },
    checkAuth ({ commit }) {
      // Check for an existing authentication token in cookies
      const token = Cookies.get('auth_token')
      if (token) {
        commit('setAuth', { isLoggedIn: true, token })
      }
    },
    logout ({ commit }) {
      return new Promise((resolve) => {
        commit('reset_user')
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        // localStorage.removeItem('role')
        localStorage.removeItem('isLoggedIn')
        Cookies.remove('auth_token')

        removeHeaderToken()
        resolve()
      })
    }
  }
}
