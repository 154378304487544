<template>
  <v-navigation-drawer
    v-model="vModel"
    id="default-toc"
    v-scroll="onScroll"
    app
    right
    class="py-4 pr-3 nav-mouse-enter"
    clipped
    floating
    width="286"
  >
    <template v-if="toc.length" #prepend>
      <div class="mb-2 pl-3 text-h5">Index</div>
    </template>

    <ul class="mb-6">
      <router-link
        v-for="({ to, level, text }, i) in toc"
        :key="to"
        v-slot="{ href, isActive }"
        :to="to"
        custom
      >
        <li
          :class="{
            'primary--text router-link-active': isActive,
            'text--secondary': !isActive,
            'pl-6': level === 3,
            'pl-9': level === 4,
            'pl-12': level === 5,
          }"
          class="pl-3 text-body-2 py-1 font-weight-regular"
        >
          <a
            :href="href"
            class="v-toc-link d-block transition-swing text-decoration-none"
            @click.prevent.stop="onClick(to, i)"
            v-text="text"
          />
        </li>
      </router-link>
    </ul>
    <template>
      <div class="mb-2 pl-4 text-h5">Menu Medis</div>
    </template>
    <!-- <ul class="mb-6">
      <li
        class="primary--text pl-6 text-body-2 font-weight-medium"
        @click="eResepNonRacikan('hantu')"
      >
        <p>E-Resep Non Racikan</p>
      </li>
      <li
        class="success--text pl-6 text-body-2  font-weight-medium"
        @click="eResep('hantu')"
      >
        <p>E-Resep Racikan</p>
      </li>

    </ul> -->
    <non-racikan-obat
      ref="NonRacikan"
      :show-dialog="dialogShowResepNonRacikan"
      :title="dialogTitleResepNonRacikan"
      :negative-button="dialogActionNBtnResepNonRacikan"
      :positive-button="dialogActionPBtnResepNonRacikan"
      :disabled-negative-btn="dialogDisableNBtnResepNonRacikan"
      :disabled-positive-btn="dialogDisablePBtnResepNonRacikan"
    />
  </v-navigation-drawer>
</template>

<script>
// Utilities
// eslint-disable-next-line no-unused-vars
import NonRacikanObat from '@/components/NonRacikanObat'

export default {
  name: 'Contents',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    NonRacikanObat
  },
  props: {
    toc: { required: true, type: [Object, Array], default: () => [] },
    syncShow: {
      type: Function,
      default: (v) => v,
      required: false
    },
    value: {
      type: [Array, Object, String, Function, Boolean],
      required: false,
      default: () => null
    }
  },

  data () {
    return {
      offsets: [],
      vModel: true,
      timeout: null,
      no_rm: '',
      no_reg: '',
      folio_id: '',
      folioYangDipakai: '',

      dialogShowResepNonRacikan: false,
      dialogTitleResepNonRacikan: 'Permintaan Resep',
      dialogProgressResepNonRacikan: false,
      dialogDisableNBtnResepNonRacikan: false,
      dialogDisablePBtnResepNonRacikan: false,

      dialogActionNBtnResepNonRacikan: () => {
        this.dialogShowResepNonRacikan = false
      },
      dialogActionPBtnResepNonRacikan: () => {}

    }
  },
  created () {
    setTimeout(() => {
      this.folioYangDipakai = JSON.parse(
        localStorage.getItem('folioIdYangDipakai')
      )

      // this.folioYangDipakai.folio_id = this.folio_id
      // this.folioYangDipakai.registration_id = this.no_reg
      // this.folioYangDipakai.no_rm = this.no_rm
      // console.log('Folioooo' + this.folioYangDipakai.folio_id)
      // console.log('Folioooo' + this.folioYangDipakai.registration_id)
      // console.log('Folioooo' + this.folioYangDipakai.no_rm)
    }, 1000)
  },

  methods: {
    async onClick (hash, i) {
      if (this.hash === hash) return

      this.scrolling = true

      this.$router.replace({ path: this.path, hash })

      await setTimeout(() => this.$vuetify.goTo(hash), 200)

      // this.scrolling = false
    },

    eResep (i) {
      console.log(i)
    },
    eResepNonRacikan (i) {
      // console.log(i)
      this.dialogShowResepNonRacikan = true
    },
    setOffsets () {
      const offsets = []
      const toc = this.toc.slice().reverse()

      for (const item of toc) {
        const section = document.getElementById(item.to.slice(1))

        if (!section) continue

        offsets.push(section.offsetTop - 48)
      }

      this.offsets = offsets
    },
    async findActiveIndex () {
      if (this.$vuetify.breakpoint.mobile) return

      const currentOffset =
        window.pageYOffset || document.documentElement.offsetTop || 0

      // If we are at the top of the page
      // reset the offset
      if (currentOffset === 0) {
        if (this.hash) {
          this.$router.replace({ path: this.path })
        }

        return
      }

      if (this.offsets.length !== this.toc.length) this.setOffsets()

      const index = this.offsets.findIndex((offset) => {
        return offset < currentOffset
      })

      let tindex = index > -1 ? this.offsets.length - 1 - index : 0

      if (
        currentOffset + window.innerHeight ===
        document.documentElement.offsetHeight
      ) {
        tindex = this.toc.length - 1
      }

      const hash = this.toc[tindex].to

      if (hash === this.hash) return

      this.scrolling = true

      await this.$router.replace({
        path: this.path,
        hash
      })

      this.scrolling = false
    },
    onScroll () {
      clearTimeout(this.timeout)

      if (this.scrolling || !this.toc.length) return

      this.timeout = setTimeout(this.findActiveIndex, 17)
    }
  },
  watch: {
    vModel (n, o) {
      this.syncShow(n)
    },
    value (n, o) {
      console.log('on wathc....', n)
      if (this.$vuetify.breakpoint.mdAndUp) {
        this.vModel = n
      } else {
        this.vModel = true
      }
    }
  }
}
</script>

<style lang="sass">
#default-toc
  ul
    list-style-type: none

  li
    border-left: 2px solid #E5E5E5

    &.router-link-active
      border-left-color: currentColor

  .v-toc-link
    color: inherit

  &.theme--dark
    li:not(.router-link-active)
      border-left-color: rgba(255, 255, 255, 0.5)
</style>
