import { apiKedua } from '../plugins/supports'
export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    postBiling ({ dispatch, commit }, data) {
      return new Promise((resolve, reject) => {
        /* Initialize the form data */
        const formData = new FormData()

        formData.append(
          'data',
          new Blob([JSON.stringify(data)], { type: 'application/json' })
        )

        apiKedua
          .post('biling/biling-results', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then((response) => {
            const { con, results } = response.data
            if (con) {
              console.log(results)
              resolve(response)
            } else {
              reject(response)
            }
          })
          .catch((err) => {
            commit('reset_user')
            localStorage.removeItem('isLoggedIn')
            reject(err)
          })
      })
    }
  }
}
