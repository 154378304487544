var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"}],staticClass:"py-4 pr-3 nav-mouse-enter",attrs:{"id":"default-toc","app":"","right":"","clipped":"","floating":"","width":"286"},scopedSlots:_vm._u([(_vm.toc.length)?{key:"prepend",fn:function(){return [_c('div',{staticClass:"mb-2 pl-3 text-h5"},[_vm._v("Index")])]},proxy:true}:null],null,true),model:{value:(_vm.vModel),callback:function ($$v) {_vm.vModel=$$v},expression:"vModel"}},[_c('ul',{staticClass:"mb-6"},_vm._l((_vm.toc),function({ to, level, text },i){return _c('router-link',{key:to,attrs:{"to":to,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, isActive }){return [_c('li',{staticClass:"pl-3 text-body-2 py-1 font-weight-regular",class:{
          'primary--text router-link-active': isActive,
          'text--secondary': !isActive,
          'pl-6': level === 3,
          'pl-9': level === 4,
          'pl-12': level === 5,
        }},[_c('a',{staticClass:"v-toc-link d-block transition-swing text-decoration-none",attrs:{"href":href},domProps:{"textContent":_vm._s(text)},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onClick(to, i)}}})])]}}],null,true)})}),1),[_c('div',{staticClass:"mb-2 pl-4 text-h5"},[_vm._v("Menu Medis")])],_c('non-racikan-obat',{ref:"NonRacikan",attrs:{"show-dialog":_vm.dialogShowResepNonRacikan,"title":_vm.dialogTitleResepNonRacikan,"negative-button":_vm.dialogActionNBtnResepNonRacikan,"positive-button":_vm.dialogActionPBtnResepNonRacikan,"disabled-negative-btn":_vm.dialogDisableNBtnResepNonRacikan,"disabled-positive-btn":_vm.dialogDisablePBtnResepNonRacikan}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }