/**
 * supports.js by tama asrory
 */
import axios from 'axios'
import permissions from './permissions'
const perm = []

/**
 * callBack function<br>
 * r = result dari pemeriksaan bernilai true (bila value kosong) dan<br>
 * bernilai false (bila value tidak kosong)<br>
 *
 * v = value original yang di periksa<br>
 * <code>(r, v) => (r ? [] : v)</code><br>
 */
export const isEmpty = (v, callBack) => {
  let result = false

  if (['null', null, ''].includes(v)) {
    result = true
  } else {
    switch (typeof v) {
      case 'object':
        switch (v.constructor.name) {
          case 'Object':
            result = !Object.keys(v).length
            break
          case 'Array':
            result = !v.length
            break
          default:
            result = false
        }
        break
      case 'string':
        result = !v.length
        break
      case 'boolean':
        result = !v
        break
      case 'undefined':
        result = true
        break
    }
  }

  if (typeof callBack === 'function') {
    result = callBack(result, v)
  }
  if (typeof callBack !== 'function' && typeof callBack !== 'undefined') {
    result = result ? callBack : v
  }
  return result
}

/**
 * if "valid" return false,
 * if "not valid" return true
 * @param schema
 * @param rules
 * @param data
 * @returns {boolean}
 */
export const inputValidator = (schema, rules, data) => {
  let notValid = false
  Object.keys(schema).map((key) => {
    // split method untuk verifikasi
    const _key = key.includes('.') ? key.split('.') : key
    const funs = schema[key].split('|')
    // console.log(funs)
    for (let i = 0; i < funs.length; i++) {
      const valid = rules[funs[i]](
        typeof _key === 'string' ? data[_key] : data[_key[0]][_key[1]]
      )
      // console.log(valid)
      if (typeof valid === 'string') {
        notValid = true
        break
      }
    }
    return null
  })
  return notValid
}

export const imgUrlToBlob = (imgUrl, callback) => {
  const tmp = new Promise((resolve, reject) => {
    axios
      .get(imgUrl)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          resolve(response.data)
        }
      })
      .catch((error) => {
        console.log(error)
        resolve([])
      })
  })
  tmp.then((d) => {
    fetch(d)
      .then((res) => res.blob())
      .then((res) => callback(res))
      .finally(() => {
        setTimeout(() => {}, 1500)
      })
  })
}

export const dataFilter = (word, datas, callback) => {
  if (!isEmpty(word)) {
    const tmp = []
    datas.map((v) => {
      if (callback(v)) {
        tmp.push(v)
      }
      return null
    })
    return tmp
  } else {
    return datas
  }
}

export const can = (permissions) => {
  for (const permission of permissions) {
    if (perm.includes(permission)) {
      return true
    }
  }
  return false
}

export const jsonPrettier = (json) => {
  json = JSON.stringify(json, undefined, 4)
  json = json
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
  const tmp = json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\\-]?\d+)?)/g,
    function (match) {
      var cls = 'number'
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = 'key'
        } else {
          cls = 'string'
        }
      } else if (/true|false/.test(match)) {
        cls = 'boolean'
      } else if (/null/.test(match)) {
        cls = 'null'
      }
      return '<span class="jsonp ' + cls + '">' + match + '</span>'
    }
  )
  return `<pre class="jsonp">${tmp}</pre>`
}

export const successMsg = (txt) => {
  // eslint-disable-next-line no-undef
  toastr.options = {
    closeButton: false,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    // positionClass: 'toast-bottom-full-width',
    preventDuplicates: false,
    onclick: null,
    showDuration: '300',
    hideDuration: '1000',
    timeOut: '5000',
    extendedTimeOut: '1000',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut'
  }
  // eslint-disable-next-line no-undef
  toastr.success(txt)
}

export const setBtnLoading = (btn, txt = '') => {
  btn
    .html('<i class="fas fa-spinner fa-spin fa-pulse fa-fw"></i> ' + txt)
}

export const resetBtnLoading = (btn, htm) => {
  btn.html(htm)
}

export const errorMsg = (txt) => {
  // eslint-disable-next-line no-undef
  toastr.options = {
    closeButton: false,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    // positionClass: 'toast-bottom-full-width',
    preventDuplicates: false,
    onclick: null,
    showDuration: '300',
    hideDuration: '1000',
    timeOut: '5000',
    extendedTimeOut: '1000',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut'
  }
  // eslint-disable-next-line no-undef
  toastr.error(txt)
}

export const apiKedua = axios.create({
  // baseURL: 'https://api-rs.dev/v1/',
  baseURL: 'http://api.rs-syafira.com/v1/',

  timeout: 20000
})

// export const baseUrl = 'https://api-rs.dev/v1/'
// export const baseUrlV2 = 'https://api-rs.dev/v2/'
// export const baseUrlEmr = 'http://emr.dev/'
// export const Penunjang = 'http://localhost/penunjang/web/index.php/'

export const baseUrlEmr = 'http://emr.rs-syafira.com/'
export const baseUrlV2 = 'http://api.rs-syafira.com/v2/'
export const baseUrl = 'http://api.rs-syafira.com/v1/'
export const Penunjang = '103.141.254.24/front-office/local/web/index.php/'

export const constBaseUrl = ''

export const HitungTanggalLahir = (tgl) => {
  return tgl
}

export const Roles = permissions
// emr/cari-pasien?q=333333

export const showMenuBase = (permissions, perm) => {
  let counter = 0
  if (!permissions) {
    return true
  }
  for (const permission of permissions) {
    if (perm.includes(permission)) {
      counter++
    }
  }
  // console.log('showMenu permissions.length => ', permissions.length, '; counter => ', counter)
  if (permissions.length === counter) {
    return true
  }
  return false
}

export const hitungImt = (tinggi, berat) => {
  // const tinggi = this.data.fisik.status_gizi.tinggi_badan
  // const berat = this.data.fisik.status_gizi.berat_badan
  // console.log(tinggi)
  // console.log(berat)
  // var tb = parseFloat(tinggi / 100)
  // var bb = berat
  // var imt = bb / (tb * tb)
  return '!@#12312'
  // this.data.fisik.status_gizi.imt = imt.toString()
}
