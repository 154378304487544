<template>
  <v-app>
    <v-app-bar v-if="isLoggedIn" color="white" app clipped-right>
      <v-app-bar-nav-icon @click="toggleDrawer"></v-app-bar-nav-icon>
      <div v-if="user">
        <div v-if="user.is_dokter == 1">
          <v-toolbar-title>
            <div v-if="patient_name">
              <b> Pasien Dipanggil : {{ patient_name }} / {{ no_rm }} </b>
              <v-btn color="success" @click="goToAsesmen" small class="pl-3">
                Check Pasien Ini?</v-btn
              >&nbsp;
              <!-- <v-btn color="primary" @click="goToResume" samll> Resume </v-btn> -->
            </div>
            <div v-else>
              <b>Pasien Belum Dipanggil</b>
            </div>
          </v-toolbar-title>
        </div>
        <div v-else>
          <v-toolbar-title>
            <b>EMR RS SYAFIRA</b>
          </v-toolbar-title>
        </div>
      </div>
      <v-spacer></v-spacer>

      <!-- <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn> -->

      <v-btn v-if="notEmpty" icon @click="toggleContents">
        <v-icon>mdi-file-tree</v-icon>
      </v-btn>
    </v-app-bar>
    <contents v-if="notEmpty" :toc="get_contents" :value="showContents" />
    <div
      v-if="isLoggedIn"
      @mouseover="mouseNow = true"
      @mouseleave="mouseNow = false"
    >
      <v-navigation-drawer
        v-model="openNav"
        app
        floating
        mini-variant-width="68"
        :mini-variant.sync="minivar"
        :expand-on-hover="$vuetify.breakpoint.smAndUp ? drawer : true"
        class="nav-mouse-enter"
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              class="font-weight-bold text-uppercase text-center"
              style="color: #00897b"
            >
              RS Syafira
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="d-none d-md-block">
            <v-icon @click="toggleDrawer" size="20">
              {{ drawer ? "mdi-radiobox-blank" : "mdi-record-circle-outline" }}
            </v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item
          class="pr-0 vertical-nav-menu-list"
          style="
            height: 68px !important;
            padding-left: 13px !important;
            margin-top: 0 !important;
            padding-right: 10px !important;
          "
        >
          <v-list-item-icon class="mr-3">
            <v-avatar color="#00796B" size="40">
              <span class="white--text text-h5"> S </span>
            </v-avatar>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              class="title"
              style="font-size: 15px !important; white-space: nowrap !important"
            >
              {{ user ? user.employee_name : "" }}
            </v-list-item-title>
            <v-list-item-subtitle
              style="
                word-wrap: normal !important;
                white-space: nowrap !important;
                font-size: 14px;
                text-transform: capitalize !important;
              "
            >
              {{ user ? user.kode_akun : "" }}
            </v-list-item-subtitle>
            <!-- <v-list-item-subtitle
              style="
                word-wrap: normal !important;
                white-space: nowrap !important;
                font-size: 14px;
                text-transform: capitalize !important;
              "
            >
              <div v-if="inactivityTimeout > 0">
                Logout Otomatis : {{ formatTime(inactivityTimeout) }}
              </div>
              <div v-else>Countdown has ended!</div>
            </v-list-item-subtitle> -->
          </v-list-item-content>
        </v-list-item>

        <v-divider />
        <v-list expand shaped class="pb-16">
          <dynamic-menu :menus="items" :is-mini="minivar" />
        </v-list>
        <v-list-item
          @click="showDialogLogout = true"
          class="vertical-nav-menu-list"
          link
          style="bottom: 0; width: 100%"
        >
          <v-list-item-icon>
            <v-icon class="v-list-item-icon-custome"> mdi-logout </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="v-list-item-title-custome">
              Keluar
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-navigation-drawer>
      <app-dialog
        :show-dialog="showDialogLogout"
        :negative-button="cancelLogout"
        :positive-button="postLogout"
        :title="'Logout'"
        :message="message"
      />
    </div>
    <v-main
      :class="mainClass"
      :style="isLoggedIn ? 'margin-top: 50px' : 'margin-top: 0px'"
    >
      <!-- {{messageTok}} -->
      <!-- <v-container fluid>
        <v-row>
          <v-col cols="12" lg="12" md="12" sm="12">
            <h4 class="mt-1">
              Kami menulis pesan ini dengan berat hati untuk mengucapkan selamat
              tinggal kepada dr.Rika Safitri yang kita cintai. Kita berdoa
              semoga Allah memberinya tempat tertinggi Jannah. Belasungkawa
              terdalam Kita untuk Sahabat Kita dr.Rika Safitri.
            </h4>
          </v-col>
        </v-row>
      </v-container> -->
      <!-- <div v-if="user">
        <div v-if="user.is_dokter == 1">
          <v-container fluid class="mb-2" v-if="trueDipanggil">
            <h3 class="mt-5 mb-1">Pasien Di Panggil Sekarang :</h3>
            <v-row>
              <v-col cols="12" md="4">
                <v-card color="red lighten-1" dark>
                  <v-card-title class="text-h6">
                    Nama Pasien : {{ patient_name }}
                  </v-card-title>
                  <v-card-subtitle class="text-h6"
                    >No. Rekam Medis : {{ no_rm }}
                  </v-card-subtitle>
                  <v-card-actions>
                    <v-btn color="success" @click="goToAsesmen" medium>
                      Assement
                    </v-btn>
                    <v-btn color="primary" @click="goToResume" medium>
                      Resume
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div> -->
      <router-view @toggle-drawer="toggleDrawer" @setup-menu="setupMenu" />

      <div v-if="showModal" class="modal">
        <div class="modal-content">
          <p>Sesi Telah Berakhir, Silahkan Login Ulang Kembali! Terima Kasih</p>
          <button @click="postLogoutBack">Logout</button>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Pusher from 'pusher-js'

import { mapGetters, mapActions } from 'vuex'
// eslint-disable-next-line no-unused-vars
import { isEmpty, errorMsg, apiKedua } from '@/plugins/supports'
import { menus } from './router'
import DynamicMenuVue from './components/DynamicMenu.vue'
import Dialog from './components/Dialog.vue'
import Contents from './components/Contents.vue'
import store from '@/store/index.js'

import { removeHeaderToken } from './utils/auth'

// eslint-disable-next-line no-unused-vars
import firebase from './firebaseInit'

export default {
  name: 'App',
  components: {
    'app-dialog': Dialog,
    'dynamic-menu': DynamicMenuVue,
    Contents
  },
  data () {
    return {
      rawatJalan: false,
      rawatInap: false,
      igd: false,
      drawer: false,

      showModal: false,
      // sessionTimeout: 10800000, // 5 minutes in milliseconds
      sessionTimeout: 10800000, // 5 minutes in milliseconds
      lastActivity: Date.now(),
      openNav: true,
      mouseNow: true,
      message: 'Apakah anda yakin akan keluar dari sistem?',
      minivar: false,
      showDialogLogout: false,
      showContents: true,
      inactivityTimeout: 60000, // 5 minutes
      inactivityTimer: null,
      items: [],
      intervalId: null,
      random_id:
        'client-' + Math.round(Math.random(100000000, 1000000000) * 1000000000),
      client: '',

      pegawai: '',
      trueDipanggil: true,
      no_rm: '',
      folio_id: '',
      is_connect: false,
      reg_id: '',
      sub_unit_id: '',
      patient_name: '',
      messageTok: '',
      dataPatient: '',
      data: {
        user: JSON.parse(localStorage.getItem('user')),
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          jenis_kelamin: '',
          cara_bayar: '',
          folio_id: '',
          reg_id: '',
          sub_unit_id: ''
        },
        inputan_dokter: {
          anamnesa: '',
          pemeriksaan_fisik: '',
          hasil_penunjang: '',
          diagnosa_utama: '',
          jawaban: '',
          riwayat_alergi: '',
          tanggal_lahir: '',
          theraphy: '',
          kode_icd_diagnosa_utama: '',
          kode_icd_diagnosa_sekunder: ''
        }
      }
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'user', 'get_contents', 'perm']),
    notEmpty () {
      return this.get_contents.length > 0
    },
    mainClass () {
      return `pt-1 ${
        this.$vuetify.breakpoint.smAndUp && this.drawer ? 'nav-mini' : ''
      }`
    }
  },
  watch: {
    '$route.params': {
      handler: function () {
        if (this.$route.meta.isRawatJalan) {
          this.rawatJalan = true
          this.setupMenu()
        } else {
          this.rawatJalan = false
          this.setupMenu()
        }
        if (this.$route.meta.isRawatInap) {
          this.rawatInap = true
          this.setupMenu()
        } else {
          this.rawatInap = false
          this.setupMenu()
        }
        if (this.$route.meta.isIGD) {
          this.igd = true
          this.setupMenu()
        } else {
          this.igd = false
          this.setupMenu()
        }
      },
      deep: true
    },
    // Watch for route changes and reset the inactivity timer
    $route (to, from) {
      if (this.isLoggedIn) {
        // this.resetInactivityTimer()
        // this.startCountdown()
      }
    }
  },
  created () {
    // this.$store.dispatch('checkAuth')
    // this.startCountdown()

    // console.log(process.env.VUE_APP_API_KEY + 'sadasdas')
    // Anu Cek Biometrik
    var urlstring = window.location.href
    // alert(urlstring)
    urlstring = urlstring.replace('/#', '')
    var url = new URL(urlstring)
    var usernya = url.searchParams.get('user')
    // localStorage.setItem('token', token)
    if (usernya !== null) {
      history.pushState({}, null, '../MOHON_SEBENTAR_MENUNGGU')

      localStorage.clear()
      localStorage.setItem('user', usernya)
      localStorage.setItem('isLoggedIn', true)
      location.href = 'http://medis.rs-syafira.com'
      // location.href = 'http://localhost:8080'
      // console.log('waww')
      if (location.href === 'http://medis.rs-syafira.com') {
        // if (location.href === 'http://localhost:8080') {
        location.reload()
      }
    }

    // alert()
    this.checkPanggilan()
    this.setupMenu(true)
    if (localStorage.getItem('drawer')) {
      this.drawer = localStorage.getItem('drawer') === 'false'
      this.minivar = localStorage.getItem('minivar') === 'false'
    }
    // this.refreshAuth()
  },

  methods: {
    ...mapActions(['logout', 'refreshAuth']),

    checkSessionTimeout () {
      // console.log('121')
      const currentTime = Date.now()
      const timeSinceLastActivity = currentTime - this.lastActivity

      if (timeSinceLastActivity >= this.sessionTimeout) {
        this.showModal = true
      } else {
        this.showModal = false
      }
    },
    resetSessionTimeout () {
      this.lastActivity = Date.now()
      this.checkSessionTimeout()
    },

    setupMenu () {
      this.items = []
      for (let i = 0; i < menus.length; i++) {
        this.items.push(
          menus[i].subheader ? menus[i] : this.genMenus(menus[i])
        )
      }
    },
    goToResume () {
      // console.log(v)

      setTimeout(() => {
        this.getDataPasien(this.no_rm)
        this.$router.push({
          name: 'resume-rawat-jalan',
          params: { no_rm: this.no_rm, folio_id: this.folio_id }
        })
        localStorage.setItem(
          'folioIdYangDipakai',
          JSON.stringify({
            no_rm: String(this.no_rm),
            folio_id: String(this.folio_id),
            registration_id: String(this.reg_id)
          })
        )
      }, 1000)
    },

    goToAsesmen () {
      // console.log(v)
      // this.$forceUpdate()

      setTimeout(() => {
        this.getDataPasien(this.no_rm)
        // this.$router.push({
        //   path: 'form-rawat-jalan',
        //   params: { no_rm: this.no_rm, folio_id: this.folio_id, number: Math.random() },
        //   query: { plan: Math.random() }
        // })

        this.$router.replace({
          name: 'ProfilePasien',
          query: {
            no_rm: this.no_rm,
            folio_id: this.folio_id,
            registration_id: String(this.reg_id),
            sub_unit_id: this.sub_unit_id,
            number: Math.random()
          }
        })

        localStorage.removeItem('pasien')

        // location.href = '/#/form-rawat-jalan'
        localStorage.setItem(
          'folioIdYangDipakai',
          JSON.stringify({
            no_rm: String(this.no_rm),
            folio_id: String(this.folio_id),
            registration_id: String(this.reg_id)
          })
        )
      }, 1000)
    },
    getDataPasien (v) {
      this.isReadonly = true
      apiKedua
        .get('emr/detail-pasien?rm=' + v, {})
        .then((response) => {
          // console.log(response)
          this.dataPatient = response.data.results
          this.data.demografi = this.dataPatient
          this.data.demografi.jenis_kelamin = {
            value: this.dataPatient.jenis_kelamin,
            text: this.dataPatient.jenis_kelamin
          }
          localStorage.setItem('pasien', JSON.stringify(this.dataPatient))
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    checkPanggilan () {
      if (this.isLoggedIn) {
        // WATCHER______________________________________________

        // console.log(firebase)
        // EVENT INTERUPT FIREBASE
        // var authRef_interupsi = firebase.database().ref('interupt') // listen to array post
        var isNewLoad = true
        // eslint-disable-next-line camelcase
        var authRef_interupsi = firebase.database().ref('antrian_poli') // listen to array post

        var ininya = this
        // ininya.$router.push({
        //           name: 'data-patient'
        //           // params: { no_rm: v.medrec_no, folio_id: v.folio_id }
        //         })
        // console.log(ininya)
        // eslint-disable-next-line camelcase
        authRef_interupsi.on('value', function (snapshot_intrp) {
          if (isNewLoad === false) {
            // if (true) {
            if (snapshot_intrp.val() != null) {
              const value = snapshot_intrp.val()
              var pegawai = JSON.parse(localStorage.getItem('user'))
              // console.log(value.employee_id + ' VALUE')
              // console.log(pegawai.employee_id + 'pegawai id')
              if (pegawai.employee_id === value.employee_id) {
                ininya.trueDipanggil = true
                ininya.patient_name = value.patient_name
                ininya.no_rm = value.no_rm
                ininya.folio_id = value.folio_id
                ininya.reg_id = value.reg_id
                ininya.sub_unit_id = value.sub_unit_id
                ininya.getDataPasien(value.no_rm)

                setTimeout(() => {
                  localStorage.removeItem('firebasePasien')
                  localStorage.setItem('firebasePasien', JSON.stringify(value))
                }, 100)
              }
            }
          } else {
            isNewLoad = false
            console.log('[ignore Interupt] First Load')
          }
          // if (i != 0) {
          //     console.log(snapshot_intrp.val());
          //     alert('wa')
          //     res.send(snapshot_intrp.val());
          // }
          // i++
        })
      }
    },
    genMenus (menus, _path) {
      const { path, meta } = menus
      let {
        auth,
        showInSidebar,
        icon,
        title,
        subheader,
        permissions,
        isRawatJalan,
        isRawatInap,
        isIGD
      } = meta

      if (isRawatJalan && this.rawatJalan) {
        showInSidebar = true
      }
      if (isRawatInap && this.rawatInap) {
        showInSidebar = true
      }
      if (isIGD && this.igd) {
        showInSidebar = true
      }

      // console.log(this.perm + ' => HASIL')
      if (!isEmpty(this.user) && this.showMenus(permissions, this.perm)) {
        if (auth && showInSidebar) {
          const tmp = { title: title, icon: icon }
          // tmp.path = path
          const cpath = _path ? _path + '/' + path : path
          tmp.path = cpath
          if (subheader) {
            let alreadyExist = false
            for (const item of this.items) {
              if (subheader === item.subheader) {
                alreadyExist = true
              }
            }
            if (!alreadyExist) {
              tmp.subheader = subheader
            }
          }
          if (menus.children) {
            tmp.children = menus.children.map((v) => this.genMenus(v, cpath))
            return tmp
          }
          return tmp
        }
      }
    },
    resetInactivityTimer () {
      // console.log('1111')
      // Reset the inactivity timer
      // clearTimeout(this.inactivityTimer)
      // this.inactivityTimer = setTimeout(() => {
      //   // Trigger the logout logic when inactivity time exceeds the timeout
      //   // this.$router.push({ name: 'logout' })
      //   // this.postLogoutBack()
      // }, this.inactivityTimeout)
    },
    postLogoutBack () {
      // this.logout().then((d) => {
      //   this.showDialogLogout = false
      //   if (!this.isLoggedIn) {
      //     this.$router.push({ name: 'Login' }).catch(() => {})
      //   }
      // })
      store.commit('reset_user')
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      localStorage.removeItem('isLoggedIn')
      // if (!this.isLoggedIn) {

      removeHeaderToken()

      setTimeout(() => {
        this.$router.push({ name: 'Login' })
        this.showModal = false
        this.resetSessionTimeout()
      }, 100)
      // }

      // removeHeaderToken()

      // resolve()
    },
    postLogout () {
      this.logout().then((d) => {
        this.showDialogLogout = false
        if (!this.isLoggedIn) {
          this.$router.push({ name: 'Login' })
        }
      })
    },
    cancelLogout () {
      this.showDialogLogout = false
    },
    toggleDrawer () {
      if (this.isLoggedIn) {
        if (this.$vuetify.breakpoint.mdAndUp) {
          // console.log('run 1111')
          this.drawer = !this.drawer
          this.minivar = !this.minivar
          this.openNav = !this.openNav
          localStorage.setItem('drawer', this.drawer.toString())
          localStorage.setItem('minivar', this.minivar.toString())
        } else {
          // console.log('run 2222')
          this.openNav = !this.openNav
        }
      }
    },
    startCountdown () {
      this.intervalId = setInterval(() => {
        if (this.inactivityTimeout > 0) {
          this.inactivityTimeout -= 1000 // Update the countdown every second
        } else {
          clearInterval(this.intervalId)
        }
      }, 1000)
    },
    formatTime (timeInMilliseconds) {
      const seconds = Math.floor((timeInMilliseconds / 1000) % 60)
      const minutes = Math.floor((timeInMilliseconds / (1000 * 60)) % 60)
      const hours = Math.floor((timeInMilliseconds / (1000 * 60 * 60)) % 24)

      return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`
    },
    pad (value) {
      return value < 10 ? `0${value}` : value
    },
    toggleContents () {
      if (this.isLoggedIn) {
        console.log('run ! smAndUp')
        this.showContents = !this.showContents
      }
    }, // connectClient () {
    //   console.log('Connecting to MQTT broker...')
    //   this.connectionStatus = 'Connecting...'
    //   this.client.connect({
    //     onSuccess: this.onConnect,
    //     onFailure: this.onFailure,
    //     useSSL: true
    //   })
    // },
    // onConnect () {
    //   this.isConnect = true
    //   this.connectionStatus = 'Connected'
    //   console.log('Connected to MQTT broker Dashbord')
    //   this.client.subscribe('fauzitest')
    // },
    // onFailure (errorMessage) {
    //   this.isConnect = false
    //   this.connectionStatus = 'Connection Failed'
    //   console.error(
    //     'Failed to connect to MQTT broker:',
    //     errorMessage.errorMessage
    //   )
    //   setTimeout(this.connectClient, 5000) // Retry every 5 seconds
    // },
    // onConnectionLost (responseObject) {
    //   if (responseObject.errorCode !== 0) {
    //     console.error('Connection lost:', responseObject.errorMessage)
    //     this.isConnect = false
    //     this.connectionStatus = 'Connection Lost'
    //     this.connectClient() // Retry connecting immediately
    //   }
    // },
    // onMessageArrived (message) {
    //   // console.log('onMessageArrived: ' + message.payloadString)
    //   // this.messages.push(message.payloadString)
    //   const jsonParse = JSON.parse(message.payloadString)

    //   if (this.isLoggedIn) {
    //     setTimeout(() => {
    //       // this.messageTok = message.payloadString
    //       if (this.data.user.employee_id === jsonParse.employee_id) {
    //         this.trueDipanggil = true
    //         this.patient_name = jsonParse.patient_name
    //         this.no_rm = jsonParse.no_rm
    //         this.folio_id = jsonParse.folio_id
    //         this.reg_id = jsonParse.reg_id
    //         this.sub_unit_id = jsonParse.sub_unit_id
    //         this.getDataPasien(jsonParse.no_rm)
    //       }
    //     }, 100)
    //   }
    // }

    pusherLis () {
      var ininya = this

      const pusher = new Pusher('app-key', {
        wsHost: '54.254.9.158',
        cluster: 'mt1', // Default cluster, adjust based on your region
        wsPort: 6001,
        forceTLS: false,
        encrypted: true,
        disableStats: true,
        enabledTransports: ['ws', 'wss']
      })

      // Berlangganan ke channel
      const channel = pusher.subscribe('my-channel')

      // Mendengarkan event
      channel.bind('my_event', function (data) {
        // alert('Received my-event with message: ' + data)
        // console.log(data)
        const jsonParse = JSON.parse(data)
        var pegawai = JSON.parse(localStorage.getItem('user'))
        console.log(jsonParse.employee_id)
        console.log(pegawai.employee_id)
        // console.log(this.isLoggedIn)
        // if (this.isLoggedIn) {
        console.log('YANG JALAN SOCKET IO')
        setTimeout(() => {
          // this.messageTok = message.payloadString
          // eslint-disable-next-line eqeqeq
          if (pegawai.employee_id.toString() === jsonParse.employee_id.toString()) {
            // console.log('JALAN KESINI')
            ininya.trueDipanggil = true
            ininya.patient_name = jsonParse.patient_name
            ininya.no_rm = jsonParse.no_rm
            ininya.folio_id = jsonParse.folio_id
            ininya.reg_id = jsonParse.reg_id
            ininya.sub_unit_id = jsonParse.sub_unit_id
            ininya.getDataPasien(jsonParse.no_rm)
          }
        }, 100)
        // }
      })
    }
  },
  mounted () {
    setTimeout(() => {
      this.pusherLis()
    }, 1000)
    // Menginisialisasi Pusher dengan Soketi

    // setTimeout(() => {
    //   const randomId =
    //   'client-' + Math.round(Math.random() * 900000000 + 23232332)
    //   // eslint-disable-next-line no-undef
    //   this.client = new Paho.Client('broker.emqx.io', 8084, randomId)
    //   this.client.onConnectionLost = this.onConnectionLost
    //   this.client.onMessageArrived = this.onMessageArrived
    //   this.connectClient()
    // }, 1000)
    // Set up a timer to check session timeout periodically
    setInterval(() => {
      if (this.isLoggedIn) {
        window.addEventListener('mousemove', this.checkSessionTimeout())
        window.addEventListener('keydown', this.checkSessionTimeout())
        window.addEventListener('scroll', this.checkSessionTimeout())
      }
    }, 1000)
  },
  beforeDestroy () {
    setInterval(() => {
      if (this.isLoggedIn) {
        window.addEventListener('mousemove', this.checkSessionTimeout())
        window.addEventListener('keydown', this.checkSessionTimeout())
        window.addEventListener('scroll', this.checkSessionTimeout())
      }
    }, 1000)
  }
}
</script>

<style scoped>
/* Add your modal styles here */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}
</style>
