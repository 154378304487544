import { apiKedua } from '../plugins/supports'
export default {
  state: {
  },
  mutations: {
  },
  getters: {
  },
  actions: {
    postFormRawatJalan ({ dispatch, commit }, data) {
      return new Promise((resolve, reject) => {
        /* Initialize the form data */
        const formData = new FormData()
        console.log(formData)
        localStorage.removeItem('form-asesment-rawat-jalan')

        formData.append(
          'data',
          new Blob([JSON.stringify(data)], { type: 'application/json' })
        )

        apiKedua.post('emr/simpan-asesmen-keperawatan', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(response => {
            const { con, results } = response.data
            if (con) {
              // localStorage.setItem('isLoggedIn', true)
              // console.log('Hasil Balikkan', results)
              localStorage.setItem('form-asesment-rawat-jalan', JSON.stringify(results)
              )
              // commit('set_user', results)
              resolve(response)
            } else {
              reject(response)
            }
          })
          .catch(err => {
            commit('reset_user')
            localStorage.removeItem('isLoggedIn')
            reject(err)
          })
      })
    },
    postFormLembarKonsultasi ({ dispatch, commit }, data) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        console.log(formData)

        localStorage.removeItem('form-lembar-konsultasi')

        formData.append(
          'data',
          new Blob([JSON.stringify(data)], { type: 'application/json' })
        )

        apiKedua.post('emr/simpan-lembar-konsultasi', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(response => {
            const { con, results } = response.data
            if (con) {
              localStorage.setItem('form-lembar-konsultasi', JSON.stringify(results)
              )
              // commit('set_user', results)
              resolve(response)
            } else {
              reject(response)
            }
          })
          .catch(err => {
            commit('reset_user')
            localStorage.removeItem('isLoggedIn')
            reject(err)
          })
      })
    },
    postFormRekamKedokteranGigi ({ dispatch, commit }, data) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        console.log(formData)

        localStorage.removeItem('form-gigi')

        formData.append(
          'data',
          new Blob([JSON.stringify(data)], { type: 'application/json' })
        )

        apiKedua.post('emr/simpan-rekam-medik-kedokteran-gigi', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(response => {
            const { con, results } = response.data
            if (con) {
              localStorage.setItem('form-gigi', JSON.stringify(results)
              )
              // commit('set_user', results)
              resolve(response)
            } else {
              reject(response)
            }
          })
          .catch(err => {
            commit('reset_user')
            localStorage.removeItem('isLoggedIn')
            reject(err)
          })
      })
    },
    postFormResumeRawatJalan ({ dispatch, commit }, data) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        console.log(formData)
        // localStorage.removeItem('form-resume-rawat-jalan')

        formData.append(
          'data',
          new Blob([JSON.stringify(data)], { type: 'application/json' })
        )

        apiKedua.post('emr/simpan-resume-rawat-jalan', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(response => {
            const { con } = response.data
            if (con) {
              resolve(response)
            } else {
              reject(response)
            }
          })
          .catch(err => {
            commit('reset_user')
            localStorage.removeItem('isLoggedIn')
            reject(err)
          })
      })
    },
    postFormLembarProgramTerapi ({ dispatch, commit }, data) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        console.log(formData)
        // localStorage.removeItem('form-lembar-program-terapi')

        formData.append(
          'data',
          new Blob([JSON.stringify(data)], { type: 'application/json' })
        )

        apiKedua.post('emr/simpan-lembar-program-terapi', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(response => {
            const { con } = response.data
            if (con) {
              resolve(response)
            } else {
              reject(response)
            }
          })
          .catch(err => {
            commit('reset_user')
            localStorage.removeItem('isLoggedIn')
            reject(err)
          })
      })
    },
    postFormKlaimRawatJalan ({ dispatch, commit }, data) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        console.log(formData)
        localStorage.removeItem('form-klaim-rawat-jalan')

        formData.append(
          'data',
          new Blob([JSON.stringify(data)], { type: 'application/json' })
        )

        apiKedua.post('emr/simpan-klaim-rawat-jalan', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(response => {
            const { con } = response.data
            if (con) {
              resolve(response)
            } else {
              reject(response)
            }
          })
          .catch(err => {
            commit('reset_user')
            localStorage.removeItem('isLoggedIn')
            reject(err)
          })
      })
    },
    postOrderPenunjang ({ dispatch, commit }, data) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        console.log(formData)
        localStorage.removeItem('form-resume-rawat-jalan')

        formData.append(
          'data',
          new Blob([JSON.stringify(data)], { type: 'application/json' })
        )

        apiKedua.post('order-penunjang/simpan-order-penunjang', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(response => {
            const { con } = response.data
            if (con) {
              resolve(response)
            } else {
              reject(response)
            }
          })
          .catch(err => {
            commit('reset_user')
            localStorage.removeItem('isLoggedIn')
            reject(err)
          })
      })
    },
    postHasilTindakanUjiFungsi ({ dispatch, commit }, data) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        console.log(formData)
        // localStorage.removeItem('form-resume-rawat-jalan')

        formData.append(
          'data',
          new Blob([JSON.stringify(data)], { type: 'application/json' })
        )

        apiKedua.post('emr/simpan-lembar-hasil-tindakan-uji-fungsi', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(response => {
            const { con } = response.data
            if (con) {
              resolve(response)
            } else {
              reject(response)
            }
          })
          .catch(err => {
            commit('reset_user')
            localStorage.removeItem('isLoggedIn')
            reject(err)
          })
      })
    }
  }
}
