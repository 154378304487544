import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import form from './form'
import biling from './biling'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    product: {},
    title: 'RS Syafira',
    contents: [],
    showContents: true,
    params: []
  },
  getters: {
    get_product (state) {
      return state.product
    },
    get_title (state) {
      return state.title
    },
    get_contents (state) {
      return state.contents
    },
    get_showcontents (state) {
      return state.showContents
    },
    // get_params (state) {
    //   return state.showContents
    // },
    params: state => {
      return state.params
    }
  },
  mutations: {
    set_product (state, data) {
      state.product = data
    },
    set_params (state, payload) {
      state.params = payload
    },
    set_title (state, data) {
      state.title = data
    },
    set_contents (state, data) {
      state.contents = data
    },
    set_showcontents (state, data) {
      state.showContents = data
    }
  },
  actions: {
    load_data ({ dispatch, commit }, data) {
      const query = new URLSearchParams(data).toString()
      return new Promise((resolve, reject) => {
        axios.get(`https://api.lakkon.id/api/v1/products?${query}`)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    }
  },
  modules: {
    auth,
    form,
    biling
  }
})
