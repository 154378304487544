// src/firebaseInit.js
import firebase from 'firebase'
import 'firebase/database'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyD49QtLBqypjt3JjtyG2caxDdkLYS_3l9A',
  authDomain: 'antrian-ke-dokter-medis-app.firebaseapp.com',
  databaseURL: 'https://antrian-ke-dokter-medis-app-default-rtdb.firebaseio.com',
  projectId: 'antrian-ke-dokter-medis-app',
  storageBucket: 'antrian-ke-dokter-medis-app.appspot.com',
  messagingSenderId: '588645249313',
  appId: '1:588645249313:web:e450bee4ff9aa40d4d8e60'
}

firebase.initializeApp(firebaseConfig)

export default firebase
