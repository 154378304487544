import Vue from 'vue'
import App from './App.vue'
import { router } from './router'
import store from './store'
import axios from 'axios'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import './assets/base.css'
import { setHeaderToken } from './utils/auth'
import vuetify from './plugins/vuetify'
import 'vue-search-select/dist/VueSearchSelect.css'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.use(VueViewer)

VueViewer.setDefaults({
  zIndexInline: 2017,
  focus: false
})
Vue.use(require('vue-moment'))
Vue.use(VueToast)
Vue.use(CKEditor)

Vue.config.productionTip = false
axios.defaults.baseURL = 'https://hr.rs-syafira.com/api/'
// axios.defaults.baseURL = 'http://localhost/hrm-syafira/web/api'
// axios.defaults.baseURL = 'http://api.rs-syafira.com/v1/'

const token = localStorage.getItem('token')
const user = localStorage.getItem('user')

if (token) {
  setHeaderToken(token)
}

if (user) {
  store.commit('set_user', JSON.parse(user))
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
