export default {
  DOKTER: 'DOKTER',
  PERAWAT: 'PERAWAT',
  Admin: 'admin',
  INAP: 'INAP',
  JALAN: 'JALAN',
  IGD: 'IGD',
  ROOT: 'ROOT',
  MANAGEMENT: 'MANAGEMENT',
  HOMEMEDIS: 'HOMEMEDIS'
}
