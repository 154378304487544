<template>
  <v-row justify="center">
    <v-dialog
      :persistent="persistentDialog"
      v-model="showDialog"
      hide-overlay
      max-width="890px"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title class="headline py-5">
          {{ title }}
          <v-btn
            v-if="negativeButton"
            color="error darken-1"
            text
            :disabled="disabledNegativeBtn"
            @click="negativeButton"
          >
            Tutup Permintaan
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-6">
          <v-row>
            <v-col cols="12" md="6">

            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="negativeButton"
            color="red darken-1"
            text
            :disabled="disabledNegativeBtn"
            @click="negativeButton"
          >
            {{ negativeBtnLabel }}
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
// eslint-disable-next-line no-unused-vars

export default {
  name: 'NonRacikan',
  props: {
    showDialog: {
      type: Boolean,
      default: false,
      required: false
    },
    title: {
      type: String,
      default: null,
      required: true
    },
    persistentDialog: {
      type: Boolean,
      default: true,
      required: false
    },
    positiveBtnLabel: {
      type: String,
      default: 'Order Ulang',
      required: false
    },
    negativeBtnLabel: {
      type: String,
      default: 'Tutup',
      required: false
    },
    positiveButton: {
      type: [Function, Boolean],
      default: () => {},
      required: false
    },
    negativeButton: {
      type: [Function, Boolean],
      default: () => {},
      required: false
    },
    disabledNegativeBtn: {
      type: Boolean,
      default: false,
      required: false
    },
    disabledPositiveBtn: {
      type: Boolean,
      default: false,
      required: false
    }
  }
}
</script>
